import React from 'react';

import Loader from 'react-loader-spinner'

const loaderConfig = {
  type: 'ThreeDots',
  height: 15,
  width: 15,
  color: '#fff',
}

export default function FilterButtons(props) {
  return (
    <div className="filter-buttons">
      {
        props.text &&
          <p>{props.text}</p>
      }
      {
        props.buttons.map((button, i) => (
          <button
            className={`button${props.active === button.value ? ' active' : ''}`}
            key={i}
            onClick={() => props.onClick(button.value)}
          >
            {button.text} {button.count !== undefined ? `(${button.count})` : ''}
          </button>
        ))
      }
      {props.children}
    </div>
  );
}

FilterButtons.defaultProps = {
  loading: false,
}
