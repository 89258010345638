import React from 'react';

import { BarChart } from './';
import { formatTraitGroupResponses } from '../helpers';

export default function Grid({ items }) {
  return (
    <div
      className="grid"
      // style={{ gridTemplateColumns: `repeat(${items.length}, 1fr)` }}
    >
      {items.map((item) => (
        <GridItem
          key={item.id}
          title={item.title}
          subtitle={item.subtitle}
          responses={item.responses}
          onClick={item.onClick}
          active={item.active}
        />
      ))}
    </div>
  );
}

Grid.defaultProps = {
  items: []
};

const GridItem = ({ title, responses, onClick, active }) => {
  const formattedResponses = formatTraitGroupResponses(responses);

  return (
    <div className={`grid-item${active ? ' active' : ''}`} onClick={onClick}>
      <h4>{title}</h4>
      {responses.length > 0 && (
        <BarChart
          hideLabels
          skills={formattedResponses.map((m, i) => ({
            x: i + 1,
            y: m.skill_total,
            color: m.rater_color
          }))}
          importance={formattedResponses.map((m, i) => ({
            x: i + 1,
            y: m.importance_total,
            color: m.rater_color
          }))}
          height={120}
          width={180}
          barWidth={30}
          // tickFormat={formattedResponses.map((m) => m.rater_title)}
        />
      )}
    </div>
  );
};

GridItem.defaultProps = {
  active: false,
  responses: [],
  subtitle: '',
  title: ''
};
