import React, { useContext } from 'react';

import { Context360 } from '../';

export default function Meta() {
  const { company, targetName, surveyName } = useContext(Context360);

  return (
    <div className="meta-360">
      <h1>{targetName}</h1>
      <p>{surveyName} Report</p>
      <p>{company.name}</p>
    </div>
  );
}
