import React from 'react';
import { FaCircle } from 'react-icons/fa';
import { BasicTable, PaginatedTable } from 'components/shared/Tables';
import { TableLinkButton } from 'components/shared/Buttons';

const columns = [
  {
    Header: 'First Name',
    accessor: 'first_name',
    filterable: true,
    sortable: false
  },
  {
    Header: 'Last Name',
    accessor: 'last_name',
    filterable: true,
    sortable: false
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: (d) => (d.status === 0 ? 'Active' : 'Inactive'),
    sortable: false
  },
  { Header: 'Email', accessor: 'email', filterable: true, sortable: false },
  { Header: 'Phone', accessor: 'phone', sortable: false },
  {
    Header: '',
    id: 'view',
    width: 40,
    sortable: false,
    accessor: (d) => <TableLinkButton url={`/managers/${d.id}`} />
  }
];

const filterOptions = [
  { id: 'first_name', object: 'self' },
  { id: 'last_name', object: 'self' }
];

const statusFilterButtons = [
  { text: 'Active', value: 0, icon: <FaCircle /> },
  { text: 'Inactive', value: 1, icon: <FaCircle /> }
];

const filterButtons = [
  {
    name: 'status',
    text: 'Status',
    default: 0,
    buttons: statusFilterButtons
  }
];

export default function Table({ companyId }) {
  // * show all company managers
  if (!companyId) {
    return (
      <div className="segment">
        <PaginatedTable
          columns={columns}
          entityName="Managers"
          filterButtons={filterButtons}
          filterOptions={filterOptions}
          keyName="data"
          url="/retrieve-company-managers/"
        />
      </div>
    );
  }

  return (
    <div className="segment">
      <BasicTable
        columns={columns}
        entityName="Managers"
        keyName="company_managers"
        url={`/companies/${companyId}/managers/`}
      />
    </div>
  );
}

Table.defaultProps = {
  companyId: null
};
