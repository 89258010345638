import React from 'react';
import { colors } from '../colors';

export default function Legend({ items }) {
  const formattedItems = [
    { color: colors.old, text: items[0] },
    { color: colors.new, text: items[1] }
  ].map((m, i) => ({ key: i, ...m }));

  return (
    <div className="legend">
      {formattedItems.map((c) => (
        <div className="legend-item">
          <span
            key={c.key}
            className="circle"
            style={{ backgroundColor: `${c.color}` }}
          ></span>
          <p>{c.text}</p>
        </div>
      ))}
    </div>
  );
}

Legend.defaultProps = {
  items: ['Oct 2017', 'Oct 2020']
};
