import React from 'react';
import logo from 'assets/images/logo.png';
import holLogo from 'assets/images/hol-3.png';

const Header = ({ surveyVariety, logoFilename }) => (
  <header>
    {surveyVariety === 3 && (
      <img src={holLogo} alt="Heart of Leadership Logo" />
    )}
    {surveyVariety !== 3 && (
      <img
        src={
          logoFilename
            ? `https://clearscreen-assets.s3.amazonaws.com/company_photos/${logoFilename}`
            : logo
        }
        alt="Logo"
      />
    )}
  </header>
);

export default Header;

Header.defaultProps = {
  logoFilename: ''
};
