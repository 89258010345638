import React from 'react';
import { Button } from 'semantic-ui-react';
import { BasicTable } from 'components/shared/Tables';

export default function AddedSurveys({
  surveys,
  onRemoveClick,
  onAddClick,
  adding
}) {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      filterable: true
    },
    {
      Header: 'Variety',
      accessor: 'variety_formatted',
      filterable: false,
      width: 150
    },
    {
      Header: 'Created',
      id: 'created',
      width: 180,
      accessor: (d) =>
        d.created_at ? new Date(d.created_at).toLocaleString() : ''
    },
    {
      Header: 'Versions',
      accessor: 'version_count',
      width: 80
    },
    {
      Header: '',
      id: 'actions',
      accessor: (d) => {
        if (adding) {
          return (
            <Button
              icon="plus"
              size="tiny"
              basic
              circular
              onClick={() => onAddClick(d.id)}
            />
          );
        }

        return (
          <Button
            icon="trash"
            size="tiny"
            basic
            circular
            onClick={() => onRemoveClick(d.id)}
          />
        );
      },
      width: 50
    }
  ];

  return (
    <div>
      <BasicTable
        columns={columns}
        entityName="Surveys"
        data={surveys}
        defaultPageSize={20}
        fetch={false}
      />
    </div>
  );
}

AddedSurveys.defaultProps = {
  adding: false,
  surveys: []
};
