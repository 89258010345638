import React, { useState, useContext } from 'react';
import Highcharts from 'highcharts/highcharts';
import HighchartsReact from 'highcharts-react-official';
import dumbbell from 'highcharts/modules/dumbbell';
import CustomEvents from 'highcharts-custom-events';
import { FaClipboardList } from 'react-icons/fa';
CustomEvents(Highcharts);
dumbbell(Highcharts);

import { formatSkillsImportance, ratersObj, ALL_RATER } from '../helpers';

import { Context360 } from '../';

import { FilterButtons } from 'components/shared/Buttons';

import { SectionTitle } from './';

const gridLineColor = 'rgba(241,245,248,0.13)';
const textColor = '#f1f5f8';

const SKILL_SORT = 'Skill';
const IMPORTANCE_SORT = 'Importance';
const GAP_SORT = 'Gap';

export default function SkillImportance({ title, data }) {
  const [sortOption, setSortOption] = useState(GAP_SORT);
  const [raterType, setRaterType] = useState(ALL_RATER);

  const reportData = useContext(Context360);
  const { raterTypes, responses } = reportData;

  const skillsImportanceData = formatSkillsImportance(responses, raterType);

  let sortedData = [...skillsImportanceData];

  if (sortOption === SKILL_SORT) {
    sortedData = skillsImportanceData.sort(
      (a, b) => b.skill_total - a.skill_total
    );
  }

  if (sortOption === IMPORTANCE_SORT) {
    sortedData = skillsImportanceData.sort(
      (a, b) => b.importance_total - a.importance_total
    );
  }

  if (sortOption === GAP_SORT) {
    sortedData = skillsImportanceData.sort((a, b) => b.gap - a.gap);
  }

  const sortButtons = [GAP_SORT, SKILL_SORT, IMPORTANCE_SORT].map((m) => ({
    text: m,
    active: sortOption === m,
    onClick: () => setSortOption(m),
    value: m
  }));

  const raterButtons = [ALL_RATER, ...Object.keys(ratersObj)]
    .map((m) => ({
      text: m === ALL_RATER ? 'All' : ratersObj[m],
      active: raterType === m,
      onClick: () => setRaterType(m),
      value: parseInt(m)
    }))
    .filter((f) => raterTypes.includes(f.value) || f.value === ALL_RATER);

  return (
    <div className="skill-importance">
      <SectionTitle icon={<FaClipboardList />} title={title} large />
      <div className="filter-btns">
        <FilterButtons
          text="Filter"
          buttons={raterButtons}
          active={raterType}
          onClick={(e) => setRaterType(e)}
        />
        <FilterButtons
          text="Sort"
          buttons={sortButtons}
          active={sortOption}
          onClick={(e) => setSortOption(e)}
        />
      </div>
      <Viz
        data={sortedData.map((d) => ({
          name: d.title,
          low: d.skill_total,
          high: d.importance_total,
          gap: d.gap
          // category_color: d.category_color
        }))}
      />
    </div>
  );
}

SkillImportance.defaultProps = {
  title: '',
  data: []
};

class Viz extends React.Component {
  render() {
    const { data } = this.props;

    const options = {
      chart: {
        type: 'dumbbell',
        inverted: true,
        backgroundColor: 'transparent',
        height: 900,
        style: {
          fontFamily: 'inherit',
          fontWeight: 600
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      subtitle: {
        text: ''
      },
      colorAxis: {
        min: 1,
        max: 5
      },
      title: {
        text: ''
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          return `<div>
              <b><u>${this.point.options.name}</u></b><br />
              <b>Skill</b>: ${this.point.options.low}
              <br />
              <b>Importance</b>: ${this.point.options.high}
              <br />
              <b>Gap</b>: ${Math.abs(this.point.options.gap).toFixed(2)}
            </div>`;
        }
      },
      xAxis: {
        type: 'category',
        gridLineColor: gridLineColor,
        labels: {
          events: {
            click: function () {
              document
                .getElementById('1')
                .scrollIntoView({ behavior: 'smooth' });
            }
          },
          style: {
            color: textColor,
            fontSize: '14px'
          },
          formatter: function () {
            const item = data.find((d) => d.name === this.value);

            if (!item) {
              return this.value;
            }

            // return `<span style="color:${item.category_color};">${item.name}</span>`;
            return `<span>${item.name}</span>`;
          }
        }
      },
      yAxis: {
        gridLineColor: gridLineColor,
        title: {
          text: ''
        },
        tickInterval: 0.1,
        min: 1,
        max: 5,
        labels: {
          style: {
            color: textColor
          }
        }
      },
      plotOptions: {
        dumbbell: {}
      },
      series: [
        {
          marker: {
            fillColor: '#5effb7',
            radius: 6
          },
          name: '',
          lowColor: '#00becc',
          connectorColor: 'rgba(241,245,248,0.8)',
          connectorWidth: 3,
          data: this.props.data
        }
      ]
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}
