import React, { Component } from 'react';
import ReactTable from 'react-table';
import axios from 'axios';

import {
  DESCRIPTOR_COGNITION,
  DESCRIPTOR_PERSONALITY
} from '@util/data/questionDescriptor';

import { FaBoxes, FaBrain, FaCircle, FaTheaterMasks } from 'react-icons/fa';

import { IconFilterButtons } from 'components/shared/Buttons';
import { TableLinkButton } from 'components/shared/Buttons';

const columns = [
  {
    Header: 'Text',
    accessor: 'text',
    filterable: true,
    sortable: false
  },
  // {
  //   Header: 'Created',
  //   id: 'created',
  //   accessor: d => d.created_at ? new Date(d.created_at).toLocaleString() : '',
  // },
  {
    Header: 'Type',
    id: 'type',
    sortable: false,
    width: 120,
    accessor: (d) => {
      if (d.multiple_choice) {
        return 'Multiple Choice';
      }

      if (d.true_false) {
        return 'True/False';
      }

      if (d.yes_no) {
        return 'Yes/No';
      }

      return '';
    }
  },
  {
    Header: '',
    sortable: false,
    id: 'view',
    width: 40,
    accessor: (d) => <TableLinkButton url={`/questions/${d.id}`} />
  }
];

const statusFilterButtons = [
  { text: 'Active', value: 0, icon: <FaCircle /> },
  { text: 'Inactive', value: 1, icon: <FaCircle /> }
];

const descriptorFilterButtons = [
  {
    text: 'Personality',
    value: DESCRIPTOR_PERSONALITY,
    icon: <FaTheaterMasks />
  },
  { text: 'Cognition', value: DESCRIPTOR_COGNITION, icon: <FaBrain /> },
  { text: 'All', value: 'all', icon: <FaBoxes /> }
];

const varietyFilterButtons = [
  { text: 'Clear Screen', value: 0 },
  { text: 'Performance', value: 1 },
  { text: 'Engagement', value: 2 },
  // { text: 'Leadership', value: 3 },
  { text: 'Corp Asst.', value: 5 }
].map((m) => ({ ...m }));

class Table extends Component {
  state = {
    loading: true,
    pages: null,
    status: 0,
    tableData: [],
    total: 0,
    variety: 0,
    descriptor: 0
  };

  table = React.createRef();

  onStatusButtonClick = (status) => {
    if (this.state.status !== status) {
      this.setState({ status });
      this.onFetchData(
        this.table.current.state,
        null,
        status,
        this.state.variety,
        this.state.descriptor
      );
    }
  };

  onDescriptorButtonClick = (descriptor) => {
    if (this.state.descriptor !== descriptor) {
      this.setState({ descriptor });
      this.onFetchData(
        this.table.current.state,
        null,
        this.state.status,
        this.state.variety,
        descriptor
      );
    }
  };

  onVarietyButtonClick = (variety) => {
    this.setState({ variety, descriptor: variety !== 0 ? null : 'all' }, () =>
      this.onFetchData(this.table.current.state, null)
    );
  };

  onFetchData = (
    state,
    instance,
    status = this.state.status,
    variety = this.state.variety,
    descriptor = this.state.descriptor
  ) => {
    const { page, pageSize, sorted, filtered } = state;

    this.setState({ loading: true });

    filtered.forEach((filter) => {
      filter.object = 'self';
      // const filterOption = this.props.filters.find(f => f.name === filter.id);

      // filter.object = filterOption.obj;
      // filter.object = self;
    });

    const postData = {
      page: page * pageSize,
      page_size: pageSize,
      sorted,
      filtered,
      status,
      variety
    };

    if (variety === 0) {
      postData.descriptor = descriptor;
    } else {
      postData.descriptor = 'all';
    }

    axios
      .post(`/retrieve-questions/`, postData)
      .then(({ data }) => {
        let pages = 0;
        if (data.total < pageSize) {
          pages = 1;
        } else {
          pages = Math.ceil(data.total / pageSize);
        }

        this.setState({
          loading: false,
          pages,
          tableData: data.data,
          total: data.total ? data.total : 0
        });
      })
      .catch((err) => {
        this.setState({
          loading: false
        });
      });
  };

  render() {
    return (
      <div className="segment">
        <div className="filter-button-header">
          <IconFilterButtons
            active={this.state.status}
            buttons={statusFilterButtons}
            loading={this.state.loading}
            onClick={this.onStatusButtonClick}
            text="Status"
          />
          <IconFilterButtons
            active={this.state.variety}
            buttons={varietyFilterButtons}
            loading={this.state.loading}
            onClick={this.onVarietyButtonClick}
            text="Variety"
          />
          {this.state.variety === 0 && (
            <IconFilterButtons
              active={this.state.descriptor}
              buttons={descriptorFilterButtons}
              loading={this.state.loading}
              onClick={this.onDescriptorButtonClick}
              text="Descriptor"
            />
          )}
        </div>
        {/*
            <FilterButtons
              active={this.state.variety}
              buttons={varietyFilterButtons}
              loading={this.state.loading}
              onClick={this.onVarietyButtonClick}
              text="Variety"
            />
          */}
        <ReactTable
          className="-ui -striped -table"
          columns={columns}
          data={this.state.tableData}
          defaultPageSize={20}
          // defaultSorted="name"
          loading={this.state.loading}
          loadingText={`Loading Questions`}
          manual
          minRows={5}
          multiSort={false}
          noDataText={`No Questions Available`}
          onFetchData={this.onFetchData}
          pages={this.state.pages}
          ref={this.table}
          rowsText="Questions"
        />
      </div>
    );
  }
}

export default Table;
