import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { Wrapped } from 'components/Layouts';
import { ErrorView } from 'components/shared/Errors';

import {
  CompanyEngagementManagement,
  CompanyForm,
  CompanyMetaBlock,
  ReportSettings,
  SurveyManagement
} from 'components/Company';
import { CompanyManagerManagement } from 'components/CompanyManager';
import { EmployeeManagement } from 'components/Employee';

class CompanyDetail extends Component {
  state = {
    data: {
      name: '',
      address1: '',
      address2: '',
      city: '',
      country: '',
      hideInvites: '',
      compCompany: false,
      holCompany: false,
      name: '',
      shortname: '',
      state: '',
      targetIndustry: '',
      zip: ''
    },
    logoFilename: '',
    managerCount: '',
    employeeCount: '',
    reportSettings: {
      coach: false,
      select: false,
      clinical: false,
      medical: false
    },
    error: null,
    loading: true
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.fetchData();
  };

  fetchData = () => {
    this.setState({ loading: true });

    axios
      .get(`/companies/${this.props.match.params.id}/`)
      .then(({ data }) => {
        this.setState({
          data: {
            address1: data.address_line_1,
            address2: data.address_line_2,
            city: data.city,
            compCompany: data.comp_assess_company,
            country: data.country,
            hideInvites: data.hide_invites,
            holCompany: data.hol_company,
            name: data.name,
            shortname: data.shortname,
            state: data.state_provence,
            targetIndustry: data.target_industry,
            zip: data.zip
          },
          managerCount: data.manager_count,
          employeeCount: data.employee_count,
          logoFilename: data.logo_url,
          reportSettings: {
            coach: data.coach_report,
            select: data.select_report,
            clinical: data.clinical_report,
            medical: data.medical_report
          },
          loading: false
        });
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.ui_message
            ? err.response.data.ui_message
            : 'Could not find the survey you were looking for',
          loading: false
        });
      });
  };

  render() {
    const { id } = this.props.match.params;
    const { data, error, reportSettings } = this.state;

    const showDecisionButtons = this.state.approvalStatus === 0;

    if (error) {
      return <ErrorView text={error} />;
    }

    return (
      <div>
        <Wrapped style={{ marginBottom: '0' }}>
          <CompanyMetaBlock
            allowUpload
            companyId={id}
            companyName={data.name}
            city={data.city}
            state={data.state}
            loading={this.state.loading}
            logoFilename={this.state.logoFilename}
            employeeCount={this.state.employeeCount}
            managerCount={this.state.managerCount}
            fetchData={this.fetchData}
          />
        </Wrapped>
        <Wrapped style={{ marginTop: '0' }}>
          <CompanyForm
            companyId={id}
            address1={data.address1}
            address2={data.address2}
            city={data.city}
            country={data.country}
            hideInvites={data.hideInvites}
            holCompany={data.holCompany}
            compCompany={data.compCompany}
            name={data.name}
            shortname={data.shortname}
            state={data.state}
            targetIndustry={data.targetIndustry}
            zip={data.zip}
            // form stuff
            onSubmit={this.fetchData}
          />
        </Wrapped>
        <Wrapped title="CLEARScreen Report Settings">
          <ReportSettings
            companyId={id}
            coach={reportSettings.coach}
            select={reportSettings.select}
            medical={reportSettings.medical}
            clinical={reportSettings.clinical}
          />
        </Wrapped>
        <CompanyEngagementManagement companyId={id} />
        <CompanyManagerManagement companyId={this.props.match.params.id} />
        <EmployeeManagement
          companyId={this.props.match.params.id}
          companyName={data.name}
        />
        <SurveyManagement companyId={this.props.match.params.id} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(CompanyDetail);
