export const colors = {
  old: '#83c0d0',
  new: '#000000',
  self: '#5effb7',
  superior: '#58e2c9',
  colleague: '#52c6db',
  subordinate: '#4ba9ed',
  all: '#458cff',
  // superior
  0: '#458cff',
  // colleague
  1: '#58e2c9',
  // direct report
  2: '#52c6db',
  // indirect report
  3: '#4ba9ed',
  // other
  4: '#4b58ed',
  // self
  5: '#5effb7',
  all: '#458cff'
};
