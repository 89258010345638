import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ReportLayout } from 'components/Layouts';

import 'components/Reports/index.scss';

import CoachReport from 'components/Reports/CoachReport';
import MedicalReport from 'components/Reports/MedicalReport';
import SelectReport from 'components/Reports/SelectReport';

import PrintButton from 'components/Reports/components/PdfDownload/PrintButton';

export default class ReportDetail extends Component {
  state = {
    error: null,
    loading: true,
    reportData: null
  };

  componentDidMount = () => this.fetchReport();

  fetchReport = () => {
    const { employeeId, calcId, opId } = this.props.match.params;

    axios
      .get(`/employee/${employeeId}/report/${calcId}/${opId}/`)
      .then(({ data }) => {
        this.setState({ loading: false, reportData: data });
      })
      .catch((err) => {
        this.setState({
          error: true
        });
      });
  };

  render() {
    const { employeeId, reportType } = this.props.match.params;
    const { pathname } = this.props.location;

    if (this.state.error)
      return (
        <div style={{ textAlign: 'center', padding: '50px' }}>
          <h1 style={{ fontSize: '3.5em' }}>ERROR!</h1>
          <p>The report you were looking for could not be located.</p>
        </div>
      );

    if (this.state.loading) return null;

    return (
      <div>
        <ReportLayout>
          {reportType === 'coach' && (
            <CoachReport data={this.state.reportData} pathname={pathname} />
          )}
          {reportType === 'medical' && (
            <MedicalReport data={this.state.reportData} pathname={pathname}>
              <PrintButton
                label="Download"
                id="report"
                filename={`${employeeId}-${reportType}`}
              />
            </MedicalReport>
          )}
          {reportType === 'select' && (
            <SelectReport data={this.state.reportData} pathname={pathname} />
          )}
        </ReportLayout>
        <ReportLayout>
          <Link className="report-back" to={`/employees/${employeeId}`}>
            Back to Employee
          </Link>
        </ReportLayout>
      </div>
    );
  }
}
