import React from 'react';
import { FaComments } from 'react-icons/fa';

import { SectionTitle } from './';

export default function Comments({ comments }) {
  if (!comments.length) return null;

  return (
    <div className="comments">
      <SectionTitle title="Comments" large icon={<FaComments />} />
      <div className="comment-grid">
        {comments.map((c) => (
          <p key={c.id}>{c.text}</p>
        ))}
      </div>
    </div>
  );
}

Comments.defaultProps = {
  comments: []
};
