import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  FaBalanceScale,
  FaBinoculars,
  FaCogs,
  FaPuzzlePiece,
  FaTheaterMasks,
  FaTrophy
} from 'react-icons/fa';
import { Loader } from 'semantic-ui-react';

import { ReportLayout } from 'components/Layouts';
import {
  AssetsAndLimitations,
  AssuranceAndReceptivity,
  ClearModel,
  Derailer,
  Footer,
  FunctionalFitGraph,
  Intro,
  PersonaBoxes,
  PersonaGraph,
  SectionTitle,
  Slider,
  WorkMotivesGraph
} from './components';

import { HolOutcomes } from 'components/Reports/components';
import derailerDataFormatter from './helpers/derailerDataFormatter';
import functionalFitDataFormatter from './helpers/functionalFitDataFormatter';
import personaGraphDataFormatter from './helpers/personaGraphDataFormatter';
import sliderDataFormatter from './helpers/sliderDataFormatter';
import workMotivesDataFormatter from './helpers/workMotivesDataFormatter';

import PrintButton from 'components/Reports/components/PdfDownload/PrintButton';

export default function HolReport({ calcContainerId }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
    document.body.className += ' hol-body';
  }, []);

  function fetchData() {
    axios
      .get(`/hol-output/${calcContainerId}/`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  }

  function fetchLeadership() {}

  if (!data)
    return (
      <div>
        <Loader active content="Loading Heart of Leadership" size="huge" />
      </div>
    );

  const { company } = data;
  // const derailerData = derailerDataFormatter(data.derailers);
  const derailerData = derailerDataFormatter({
    calculation_container: {
      derailers: data.hol.derailers
    }
  });
  // const functionalFitData = functionalFitDataFormatter(data);
  // const personaGraphData = personaGraphDataFormatter(data);
  // const peopleOrientationData = sliderDataFormatter(data);
  // const processDisciplineData = sliderDataFormatter(data, 'processDiscipline');
  // const workMotivesData = workMotivesDataFormatter(data);
  const candidateName = data.employee ? data.employee.full_name : 'Candidate';
  const activePersona = data.hol.lcl_likeness;

  return (
    <ReportLayout hol>
      <PrintButton
        label="Download"
        id="holReport"
        cls={'hol'}
        filename={`${candidateName} Heart of Leadership`}
        pathname={window.location.pathname}
        hol
      />
      <div id="holReport">
        <Intro
          fullName={candidateName}
          companyName={company.name}
          // reportType="Heart of Leadership"
          hol
          // reportSubtitle="For onboarding and development"
        />
        <HolOutcomes
          panResult={data.hol.pan_result}
          executiveValue={data.hol.hol_exec}
          managerValue={data.hol.hol_man}
          engineerValue={data.hol.hol_staff}
        />
        <div className="page"></div>
        <SectionTitle
          title="Supplementary Info"
          icon={<FaCogs />}
          text={
            <React.Fragment>
              <p>
                Supplemental information in the form of Key Watchouts (if any)
                and Work Motives follow.
              </p>
            </React.Fragment>
          }
        />
        <SectionTitle
          title="Persona"
          icon={<FaTheaterMasks />}
          text={
            <React.Fragment>
              <p>
                Plotting <b>People Orientation</b> and <b>Process Discipline</b>{' '}
                reveals this individual's 'Leader Persona'. There are eight (8)
                possible Leader Personas, based upon the degrees of the two
                component factors. A 'High Performance Leadership' range is
                represented by the oval in the upper right area of the plot
                below. This range captures about two-thirds of the best leaders.
                Any Leader Persona can be a high performer, but 'Competitors',
                for example, tend to have more high perfomers because they are
                the most prevalent Leader Persona.
              </p>
            </React.Fragment>
          }
        />
        <div style={{ marginBottom: '3em' }}>
          <PersonaBoxes activePersona={activePersona} />
        </div>
        <SectionTitle
          title="Watchouts"
          icon={<FaBinoculars />}
          text={
            <React.Fragment>
              <p>
                <b>Watchouts</b> are sets of characteristics that have been
                shown to hinder the positional level, career longevity, work
                performance, and co-worker regard of leaders in the workplace.
              </p>
              <p>
                {candidateName}'s potential Risk levels associated with these
                watchouts are presented below.{' '}
                <b>
                  Icons presented in <span className="green">green</span>{' '}
                  indicate low risk for associated Watchout. Icons presented in{' '}
                  <span className="red">red</span> indicate elevated risk.
                </b>
              </p>
            </React.Fragment>
          }
        />
        {derailerData.map(({ id, name, icon, hol, triggered }) => (
          <Derailer
            key={id}
            name={name}
            icon={icon}
            text={hol}
            triggered={triggered}
          />
        ))}
        <div className="page"></div>
        <Footer />
      </div>
    </ReportLayout>
  );
}
