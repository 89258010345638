import React from 'react';
import Highcharts from 'highcharts/highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
highchartsMore(Highcharts);

export default function WorkMotivesGraph({ data, fillColor }) {
  if (!data.length) return <p>No data!</p>;

  const options = {
    chart: {
      polar: true,
      height: '60%',
      width: 800,
      type: 'area',
      style: {
        fontFamily: 'Open Sans'
      }
    },
    credits: {
      enabled: false
    },
    title: '',
    pane: {
      size: '90%'
    },
    plotOptions: {
      area: {
        fillColor,
        marker: {
          enabled: false
        }
      },
      series: {
        states: {
          hover: {}
        }
      }
    },
    xAxis: {
      categories,
      tickmarkPlacement: 'on',
      lineWidth: 0,
      gridLineColor: '#ccc',
      labels: {
        useHTML: true,
        formatter: ({ value }) => {
          if (typeof value !== 'object') return null;

          return `<div><h3 class="spiderweb-title">${value.name}</h3>${
            value.subtitle
              ? `<p class="spiderweb-subtitle">${value.subtitle}</p>`
              : ''
          }</div>`;
        }
      }
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      tickInterval: 20,
      min: 0,
      max: 39,
      // gridLineColor: 'transparent',
      labels: {
        enabled: true
      }
    },
    tooltip: {
      formatter() {
        return `<div>${this.x.name}<br />WM: <b>${this.y}</b></div>`;
      },
      shared: true
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    series: [
      {
        name: 'WM',
        data,
        pointPlacement: 'on'
      }
    ]
  };

  return (
    <div className="work-motives-graph">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

WorkMotivesGraph.defaultProps = {
  data: [],
  fillColor: `rgba(255, 127, 0, 0.64)`
};

const categories = [
  { name: 'Realistic/Tangible', subtitle: 'Manufacturing, Trades' },
  { name: 'Investigative/Analytical', subtitle: 'Science, Technology' },
  { name: 'Artistic/Creative', subtitle: 'Entertainment, Publishing' },
  { name: 'Social/Supportive', subtitle: 'Education, Healthcare' },
  { name: 'Enterprising/Influencing', subtitle: 'Management, Sales' },
  { name: 'Conventional/Procedural', subtitle: 'Accounting, Administration' }
];
