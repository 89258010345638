import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { BasicTable } from 'components/shared/Tables';
import SurveyModal from './SurveyModal';
import ResendButton from './ResendButton';

import surveyVariety, {
  CLEARSCREEN_VALUE,
  PERFORMANCE_REVIEW
} from '@util/data/surveyVariety';

export default function Table({
  admin,
  employeeId,
  onLoad,
  refresh,
  reportsEnabled
}) {
  if (!employeeId) return null;

  const columns = [
    {
      Header: 'Survey',
      accessor: 'name'
    },
    {
      Header: 'Variety',
      accessor: 'variety_formatted'
    },
    {
      Header: 'Reports Available',
      accessor: 'report_count'
    },
    // {
    //   Header: 'Status',
    //   id: 'status',
    //   accessor: (d) => {
    //     if (d.approval_status < 2) {
    //       return d.approval_status_formatted;
    //     } else {
    //       return d.activity_status_formatted;
    //     }
    //   }
    // },
    {
      Header: 'Requested',
      id: 'requested',
      accessor: (d) => new Date(d.created_at).toLocaleDateString()
    },
    {
      Header: '',
      id: 'view',
      width: admin ? 80 : 40,
      accessor: (d) => {
        if (d.variety === CLEARSCREEN_VALUE) {
          if (admin) {
            return (
              <div>
                <Button
                  as={Link}
                  to={`/evaluations/${d.id}`}
                  icon="edit"
                  basic
                  size="tiny"
                  title="View Evaluation"
                />
                <ResendButton id={d.id} />
              </div>
            );
          }

          return (
            <div>
              <SurveyModal
                reports={d.available_reports}
                employeeId={employeeId}
                surveyId={d.id}
                surveyName={d.name}
                reportsEnabled={reportsEnabled}
              />
              {admin && <ResendButton id={d.id} />}
            </div>
          );
        }

        if (
          d.variety === PERFORMANCE_REVIEW &&
          d.survey_container_id &&
          !admin
        ) {
          return (
            <div>
              <Button
                as={Link}
                to={`/employees/${employeeId}/performance/${d.survey_container_id}`}
                icon="eye"
                basic
                size="tiny"
              />
            </div>
          );
        }

        if (
          d.variety === PERFORMANCE_REVIEW &&
          d.survey_container_id &&
          admin
        ) {
          return (
            <div>
              <Button
                as={Link}
                to={`/requests/${d.survey_container_id}`}
                icon="edit"
                basic
                size="tiny"
              />
              <ResendButton id={d.id} />
            </div>
          );
        }

        return '';
      }
    }
  ];

  return (
    <div className="segment">
      <BasicTable
        columns={columns}
        entityName="Surveys"
        keyName="surveys"
        refresh={refresh}
        returnData={onLoad}
        url={`/employees/${employeeId}/surveys/`}
      />
    </div>
  );
}

Table.defaultProps = {
  admin: false,
  refresh: false,
  reportsEnabled: []
};
