import React from 'react';
import {
  FaCrown,
  FaAngry,
  FaBatteryEmpty,
  FaTheaterMasks,
  FaPalette,
  FaExclamationTriangle,
  FaSnowflake,
  FaBomb,
  FaCut,
  FaStopwatch,
  FaHeadphones
} from 'react-icons/fa';

const iconProps = {
  size: 60
};

const derailers = {
  arrogant: {
    text: `High intellect, high assertion, and high open-mindedness. Taken to the extreme--and combined with elevated vigilance--those with the Arrogant Watchout can be experienced as intellectually hostile, and presumptuous.`,
    hol: `Strong ability to Think, to Challenge, and to Anticipate yet with relatively little Optimism & Trust.`,
    icon: <FaCrown {...iconProps} />
  },
  callous: {
    text: `Socially bold yet very reserved. Those with the Callous Watchout may step into situations readily yet will will not leave behind the "warm fuzzies."`,
    hol: `An Elevating nature combined with a relative lack of Humility & Love.`,
    icon: <FaAngry {...iconProps} />
  },
  deflated: {
    text: `Combination of lack of Mission focus, lack of Challenging nature, lack of Inspiring demeanor, lack of Elevating attitude, lack of Resoluteness, and lack of Courage.`,
    hol: ` Combination of lack of Mission focus, lack of Challenging nature, lack of Inspiring demeanor, lack of Elevating attitude, lack of Resoluteness, and lack of Courage.`,
    icon: <FaBatteryEmpty {...iconProps} />
  },
  detached: {
    text: `Relative lack of Resolve combined with the inability or unwillingness to Teach & Learn.`,
    hol: `Relative lack of Resolve combined with the inability or unwillingness to Teach & Learn.`,
    icon: <FaCut {...iconProps} />
  },
  dramatic: {
    text: `Vacillation between enthusiasm to reserve characterizes the Dramatic Watchout. May be perceived as inconsistent.`,
    hol: `Combination of an Inspiring social style with a relative lack of Humility & Love.`,
    icon: <FaTheaterMasks {...iconProps} />
  },
  eccentric: {
    text: `Intelligent and emotionally stable yet expedient, sporadic in communications, and lacking in urgency. May be perceived as not having clear purpose.`,
    hol: `Strong ability to Think with clear Mission focus yet lacking Values, Engagement, and Courage.`,
    icon: <FaPalette {...iconProps} />
  },
  guarded: {
    text: `Emotional reactivity and severe shyness. Often strongly rated in analytics and in commitment yet often have significantly shortened tenures.`,
    hol: `Relative lack of a Mission focus in combination with a relative lack of an Elevating nature.`,
    icon: <FaExclamationTriangle {...iconProps} />
  },
  hesitant: {
    text: `Extreme Reactivity and marked apprehensiveness.`,
    hol: `Relative lack of a Mission focus combined with a relative lack of Resolve.`,
    icon: <FaSnowflake {...iconProps} />
  },
  hostile: {
    text: `May appear warm and open-minded at times, yet they are also reactive, expedient, and disorderly. While adaptive and dynamic, those with this Watchout may compound their ultimate lack of execution with episodes of over reactivity which creates friction.`,
    hol: `Appear to exhibit Humility and Love as well as Anticipation, yet may lack clear Mission, Values, and Goals (i.e., the three Evangelize factors).`,
    icon: <FaBomb {...iconProps} />
  },
  obsessive: {
    text: `Perfectionistic tendencies coupled with elevated rule-consciousness, detail orientation, and deference to authority.  May take a heads-down approach to problem-solving.`,
    hol: `Combination of clear Values and Goals yet with a relative lack of a Challenging demeanor.`,
    icon: <FaStopwatch {...iconProps} />
  },
  respondent: {
    text: `Reactive, expedient, and tolerant of disorder.  Can be viewed as flexible and adaptive but may lack initiative and will not be considered "pace setters."`,
    hol: `Relative lack of clear Mission, Values, and Goals (i.e., the three Evangelize factors).`,
    icon: <FaHeadphones {...iconProps} />
  }
};

export default function derailerDataFormatter(data) {
  if (!data.calculation_container || !data.calculation_container.derailers)
    return [];

  return data.calculation_container.derailers
    .filter((d) => derailers[d.name])
    .map((derailer) => ({
      ...derailer,
      ...derailers[derailer.name]
    }));
}
