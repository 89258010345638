import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Modal, Form, Button, Divider } from 'semantic-ui-react';

import { PersonSearch } from './AddForm';

export default function Employee360s({ employeeId, showClosed, isAdmin }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .post(`/employees/${employeeId}/retrieve-360s/`, {
        company_employee_id: employeeId
      })
      .then(({ data }) => {
        setItems(data.sort((a, b) => b.closed - a.closed));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  return (
    <div className="list-360">
      {!loading && items.length === 0 && (
        <p>This employee has not been the target of a 360 yet.</p>
      )}
      {items.map((item) => (
        <div key={item.id} className="list-item-360">
          <div className="meta">
            <h3>{item.name}</h3>
            <p>Created {new Date(item.created_at).toLocaleString()}</p>
            <span className={`label${item.closed ? ' closed' : ''}`}>
              {item.closed && 'Ready to view'}
              {!item.closed && 'In progress'}
            </span>
          </div>
          {item.survey_invite_approval_status === 0 && (
            <EditModal
              onUpdate={fetchData}
              containerId={item.id}
              inviteId={item.survey_invite_id}
            />
          )}
          {item.closed && (
            <Button
              basic
              icon="eye"
              circular
              title="View 360"
              size="mini"
              as={Link}
              to={
                isAdmin
                  ? `/360/${item.id}`
                  : `/employees/${employeeId}/360/${item.id}`
              }
            />
          )}
          {isAdmin && (
            <Button
              basic
              icon="mail outline"
              circular
              size="mini"
              as={Link}
              to={`/requests/${item.survey_invite_id}`}
              title="View Request"
            />
          )}
        </div>
      ))}
    </div>
  );
}

Employee360s.defaultProps = {
  isAdmin: false,
  showClosed: true
};

const EditModal = ({ containerId, inviteId, onUpdate }) => {
  const [isOpen, setOpen] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [companyId, setCompanyId] = useState(null);

  if (!containerId || !inviteId) return null;

  function onToggle() {
    setOpen(!isOpen);
  }

  useEffect(() => {
    if (!isOpen) return;

    fetchData();
  }, [isOpen]);

  function fetchData() {
    axios
      .get(`/survey-requests/${inviteId}/`)
      .then(({ data }) => {
        setSurveys(data.surveys_info);
        if (data.requested_for_formatted) {
          setCompanyId(data.requested_for_formatted.id);
        }
      })
      .catch((err) => {
        setSurveys([]);
      });
  }

  function onSurveyAdd(person) {
    const req = {
      survey_container_id: containerId
    };

    if (person.manager) {
      req.company_manager_id = person.id;
    } else {
      req.employee_id = person.id;
    }

    axios
      .post(`/survey-container/${containerId}/add-survey/`, req)
      .then(() => {
        fetchData();
      })
      .catch((err) => {});
  }

  return (
    <React.Fragment>
      <Button
        icon="pencil"
        circular
        basic
        title="Edit"
        size="mini"
        onClick={onToggle}
      />
      <Modal open={isOpen} onClose={onToggle}>
        <Modal.Content>
          {surveys.map((s) => (
            <p>
              {s.company_employee_name}
              {!s.target && (
                <RemoveButton
                  surveyId={s.id}
                  surveyContainerId={containerId}
                  onSuccess={fetchData}
                />
              )}
            </p>
          ))}
          {companyId && (
            <div className="create-360">
              <Divider />
              <h4>Add more evaluators</h4>
              <Form>
                <PersonSearch
                  companyId={2}
                  activeIds={[]}
                  onSelect={onSurveyAdd}
                  showTitle={false}
                />
              </Form>
              <PersonAdd onSuccess={fetchData} companyId={companyId} />
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" onClick={onToggle} basic />
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

const RemoveButton = ({ surveyId, surveyContainerId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  if (!surveyId) return null;

  function onClick() {
    setLoading(true);

    const req = {
      survey_id: surveyId,
      survey_container_id: surveyContainerId
    };

    axios
      .post(`/survey-container/${surveyContainerId}/remove-survey/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <Button
      circular
      basic
      icon="trash"
      onClick={onClick}
      loading={loading}
      disabled={loading}
    />
  );
};

const PersonAdd = ({ activeIds, companyId, surveyContainerId, onSuccess }) => {
  function onAdd(p) {
    console.log(p);
  }

  return (
    <Form>
      <PersonSearch
        companyId={2}
        activeIds={[]}
        onSelect={onSurveyAdd}
        showTitle={false}
      />
    </Form>
  );
};

PersonAdd.defaultProps = {
  activeIds: [],
  companyId: null
};
