import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { SET_TOAST } from 'duck/toast';

import Donut from './Donut';

function ProfileItem({
  name,
  id,
  employeeId,
  reportsEnabled,
  calculationContainerId,
  cosTotal,
  cogScore,
  steqScore,
  similarity,
  surveyVariety,
  completed,
  evaluated,
  fetchData,
  viewable,
  dispatch
}) {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(true);
  const [viewableValue, setViewableValue] = useState(false);

  useEffect(() => {
    setViewableValue(viewable);
  }, [viewable]);

  function onRunClick() {
    if (!id || !calculationContainerId) return;

    setLoading(true);

    const postData = {
      calculation_container_id: calculationContainerId,
      op_ids: [id]
    };

    axios
      .post(`/calculation-containers/${id}/complete-profile/`, postData)
      .then(() => fetchData())
      .then(() => {
        setLoading(false);
      })
      .then(() => setOpen(true))
      .catch((err) => {
        setLoading(false);
      });
  }

  function onAcceptClick() {
    setLoading(true);

    axios
      .post(`/calculation-containers/${id}/mark-evaluated/`, {
        op_calc_container_id: id
      })
      .then(() => fetchData())
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function onViewableChange(e, d) {
    const postData = {
      viewable: d.checked,
      op_calc_id: id
    };

    setLoading(true);
    setViewableValue(d.checked);

    axios
      .post(`/opcc-viewable/`, postData)
      .then(() => setLoading(false))
      .then(() => {
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Profile Settings Updated!'
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_TOAST,
          payload: {
            negative: true,
            message: err.response.data.ui_message
          }
        });
      });
  }

  return (
    <div className="calc-profile-item">
      <div className="meta">
        <div className="meta-name">
          <h1>
            {name} <Loader active={loading} inline />
          </h1>
        </div>
        {surveyVariety !== 3 && (
          <div className="actions">
            {completed && !evaluated && (
              <div style={{ textAlign: 'center' }}>
                <Button
                  content="Accept Results"
                  onClick={onAcceptClick}
                  loading={loading}
                  icon="thumbs up"
                  labelPosition="right"
                  size="tiny"
                  style={{
                    background: '#37bcc8',
                    color: '#fff'
                  }}
                />
              </div>
            )}
            {completed && (
              <div>
                {evaluated && (
                  <div className="profile-actions">
                    <Form>
                      <Form.Checkbox
                        label="Viewable"
                        toggle
                        disabled={loading}
                        checked={viewableValue}
                        onChange={onViewableChange}
                      />
                    </Form>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {completed && reportsEnabled.length > 0 && (
          <div className="actions">
            <div className="report-links">
              {reportsEnabled.map((report) => (
                <Link
                  key={report}
                  to={`/employees/${employeeId}/report/${calculationContainerId}/${id}/${report}`}
                  className="report-link-button"
                  target="_blank"
                >
                  {report} Report
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
      {!completed && (
        <div className="calc-profile-results">
          <Button
            icon="play"
            size="tiny"
            basic
            onClick={onRunClick}
            loading={loading}
            title="Evaluate Now"
          />
        </div>
      )}
      {completed && (
        <div className="calc-profile-results">
          <div className="profile-stats">
            <Donut value={cosTotal} title="Chance of Leadership Success" />
            <Donut value={steqScore} title="FPF" />
            <div className="profile-stat-box">
              <h2>{cogScore}</h2>
              <p>Cognitive</p>
            </div>
            <div className="profile-stat-box">
              <h2>{similarity}</h2>
              <p>Profile Similarity</p>
            </div>
          </div>
        </div>
      )}
      {/* {completed && isOpen && (
        <div>
          <div>
            {evaluated && (
              <div className="profile-actions">
                <Form>
                  <Form.Checkbox
                    label="Viewable"
                    toggle
                    checked={viewableValue}
                    onChange={onViewableChange}
                  />
                </Form>
              </div>
            )}
          </div>
          <div className="profile-stats">
            <Donut value={cosTotal} title="Chance of Leadership Success" />
            <Donut value={steqScore} title="FPF" />
            <div className="profile-stat-box">
              <h2>60</h2>
              <p>Cognitive</p>
            </div>
            <div className="profile-stat-box">
              <h2>{similarity}</h2>
              <p>Profile Similarity</p>
            </div>
          </div>
          {!evaluated && (
            <div style={{ textAlign: 'center' }}>
              <Button
                content="Accept Results"
                size="large"
                onClick={onAcceptClick}
                loading={loading}
                icon="thumbs up"
                labelPosition="right"
                style={{
                  margin: '20px 0',
                  background: '#37bcc8',
                  color: '#fff'
                }}
              />
            </div>
          )}
        </div>
      )} */}
    </div>
  );
}

ProfileItem.defaultProps = {
  name: 'Profile Name',
  calculationContainerId: null,
  id: null,
  cosTotal: 0,
  completed: false,
  evaluated: false,
  reportsEnabled: [],
  similarity: 'Low',
  cogScore: 0,
  steqScore: 0,
  viewable: false
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ProfileItem);
