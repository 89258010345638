import React from 'react';
import { TraitGroup } from './';

export default function TraitSection({
  icon,
  sections,
  title,
  subtitle,
  questions
}) {
  return (
    <div className="trait-section">
      <div className="trait-section-meta">
        {icon}
        <h1>{title}</h1>
        <div className="section-tags">
          {sections.map((s) => (
            <button
              key={`tag${s.id}`}
              onClick={() =>
                document.getElementById(s.id).scrollIntoView({
                  behavior: 'smooth'
                })
              }
            >
              {s.title}
            </button>
          ))}
        </div>
      </div>
      <div className="sections">
        {sections.map((s) => (
          <div id={s.id} key={s.id}>
            <TraitGroup
              key={s.id}
              title={s.title}
              questions={questions.filter((f) =>
                s.question_skills.includes(f.skill)
              )}
              subtitle={s.subtitle}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

TraitSection.defaultProps = {
  questions: [],
  sections: [],
  subtitle: '',
  title: ''
};
