import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { Form } from 'semantic-ui-react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

import BasicForm from 'components/shared/Forms/BasicForm';
import {
  ActionButton,
  BasicButton,
  SubmitButton
} from 'components/shared/Buttons';

export default function AddForm({
  companyId,
  employeeId,
  employeeName,
  onSuccess
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [surveyId, setSurveyId] = useState(null);
  const [surveys, setSurveys] = useState([]);
  const [evaluators, setEvaluators] = useState([]);

  useEffect(() => {
    fetchSurveys();
  }, [companyId]);

  function onSubmit() {
    setLoading(true);
    setError(null);

    const req = {
      survey_id: surveyId,
      company_id: companyId,
      target_id: employeeId,
      evaluators: evaluators.map((e) => {
        const evaluator = {
          employee_id: e.id,
          rater_type: e.relationship
        };

        if (e.manager) {
          evaluator.company_manager_id = evaluator.employee_id;
          delete evaluator.employee_id;
        }

        if (e.other_relationship || e.is_other) {
          evaluator.other_rater_context = e.other_relationship;
          evaluator.employee_id = null;
          evaluator.first_name = e.first_name;
          evaluator.last_name = e.last_name;
          evaluator.email = e.email;

          if (e.relationship !== 4) {
            evaluator.alt_rater_type = e.relationship;
          } else {
            evaluator.rater_type = 4;
          }
        }
        return evaluator;
      })
    };

    axios
      .post(`/request-360/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setError(err.response.data.ui_message);
        setLoading(false);
      });
  }

  function fetchSurveys() {
    if (!companyId) return;

    axios
      .post(`/companies/${companyId}/retrieve-surveys/`, {
        company_id: companyId,
        status: 0
      })
      .then(({ data }) => {
        setSurveys(data.surveys.filter((f) => f.variety === 4));
        // setSurveys(data.surveys.filter((f) => true));
      })
      .catch(() => {});
  }

  function onEvalAdd(person) {
    setEvaluators([...evaluators, person]);
  }

  function onEvalRemove(personId) {
    setEvaluators([...evaluators].filter((f) => f.id !== personId));
  }

  return (
    <div className="create-360">
      <p>
        You are initiating a 360 Report for <b>{employeeName}</b>
      </p>
      <p>
        After picking a survey and evaluators, your request will be sent to BCK
        for approval.
      </p>
      <Form>
        <Form.Select
          label="360 Survey"
          options={surveys.map((m) => ({
            key: m.id,
            text: m.name,
            value: m.id
          }))}
          value={surveyId}
          onChange={(e, d) => setSurveyId(d.value)}
          placeholder="Select one..."
        />
      </Form>
      <div style={{ marginTop: '1em' }}>
        <EvaluatorManagement
          targetName={employeeName}
          evaluators={evaluators}
          onAdd={onEvalAdd}
          onRemove={onEvalRemove}
          companyId={companyId}
        />
      </div>
      {evaluators.length > 0 && (
        <div className="submit-360" style={{ marginTop: '2em' }}>
          {/* <SubmitButton
            disabled={!surveyId || !evaluators.length}
            loading={loading}
            text="Send to BCK for approval"
            onClick={onSubmit}
          /> */}
          <ConfirmSubmit
            onSubmit={onSubmit}
            loading={loading}
            disabled={!surveyId || !evaluators.length}
          />
        </div>
      )}
    </div>
  );
}

function ConfirmSubmit({ onSubmit, disabled, loading }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      {!isOpen && (
        <SubmitButton
          disabled={disabled}
          loading={loading}
          text="Send to BCK for approval"
          onClick={() => setOpen(true)}
        />
      )}
      {isOpen && (
        <div>
          <p>Are you sure you want to submit this?</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BasicButton onClick={() => setOpen(false)} text="Cancel" />
            <SubmitButton
              style={{ marginLeft: '8px' }}
              text="Yes"
              onClick={onSubmit}
              loading={loading}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function EvaluatorManagement({
  companyId,
  evaluators,
  targetName,
  onAdd,
  onRemove
}) {
  const [queue, setQueue] = useState(null);
  const [relationship, setRelationship] = useState(null);
  const [otherRelationship, setOtherRelationship] = useState('');

  const evaluatorIds = evaluators.map((m) => m.id);

  function onConfirm() {
    onAdd({
      ...queue,
      relationship,
      other_relationship: otherRelationship
    });

    setRelationship(null);
    setOtherRelationship('');
    setQueue(null);
  }

  return (
    <div>
      <Form>
        <PersonSearch
          companyId={companyId}
          onSelect={(emp) => setQueue(emp)}
          activeIds={evaluatorIds}
          targetName={targetName}
          onOtherSelect={(emp) => {
            onAdd({
              ...emp,
              relationship: emp.relationship,
              is_other: true
            });
          }}
        />
      </Form>
      {queue && (
        <div className="queue">
          <p>
            You are about to add <b>{queue.full_name}</b> as an evaluator.
          </p>
          <p>
            What is the relationship between <b>{queue.full_name}</b> and{' '}
            <b>{targetName}</b>?
          </p>
          <Form style={{ marginBottom: '1.5em' }}>
            <Form.Select
              label="Relationship"
              placeholder="Select one..."
              options={relationshipOptions.filter((f) => f.value !== 4)}
              value={relationship}
              onChange={(e, d) => setRelationship(d.value)}
            />
            {relationship === 4 && (
              <Form.Input
                label="What is this other relationship?"
                value={otherRelationship}
                onChange={(e, d) => setOtherRelationship(d.value)}
              />
            )}
          </Form>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SubmitButton
              text="Add Evaluator"
              onClick={onConfirm}
              style={{ marginRight: '8px' }}
              disabled={
                relationship === null ||
                (relationship === 4 && !otherRelationship)
              }
            />
            <BasicButton
              text="Clear Selection"
              onClick={() => {
                setQueue(null);
                setRelationship(null);
                setOtherRelationship('');
              }}
            />
          </div>
        </div>
      )}
      <Evaluators
        people={evaluators.map((p) => ({
          ...p,
          onRemove: () => onRemove(p.id)
        }))}
      />
    </div>
  );
}

EvaluatorManagement.defaultProps = {
  evaluators: []
};

const relationshipOptions = [
  { text: 'Superior', value: 0 },
  { text: 'Colleague', value: 1 },
  { text: 'Direct Report', value: 2 },
  { text: 'Indirect Report', value: 3 },
  { text: 'Other', value: 4 }
];

function Evaluators({ people }) {
  if (!people.length) return null;

  return (
    <div className="current-evals">
      <h5>Current Evaluators ({people.length})</h5>
      <div className="eval-grid">
        {people
          .sort((a, b) => a.relationship - b.relationship)
          .map((p) => (
            <div key={p.id} className="eval-item">
              <div className="name">
                <p>{p.full_name}</p>
                <ActionButton
                  negative
                  text={<FaRegTrashAlt />}
                  onClick={p.onRemove}
                />
              </div>
              <div className="relation">
                <p>
                  {
                    relationshipOptions.find((r) => r.value === p.relationship)
                      .text
                  }
                  {p.other_relationship && ` (${p.other_relationship})`}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

Evaluators.defaultProps = {
  people: []
};

export function PersonSearch({
  companyId,
  onSelect,
  onOtherSelect,
  activeIds,
  showTitle,
  targetName
}) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [isEmployee, setIsEmployee] = useState(true);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  function onOtherAdd(op) {
    onOtherSelect({
      id: uuidv4(),
      first_name: op.first_name,
      full_name: `${op.first_name} ${op.last_name}`,
      last_name: op.last_name,
      email: op.email,
      relationship: op.relationship,
      other_relationship: op.other_rater_context
    });
    setIsEmployee(true);
  }

  function onInputChange(empId) {
    const emp = options.find((opt) => opt.value === empId);

    if (!emp) return;

    onSelect({ id: emp.value, full_name: emp.text, manager: emp.manager });
    setOptions([]);
  }

  function fetchData(val) {
    if (val.length === 0) return setOptions([]);

    const req = {
      value: val,
      company_id: companyId
    };

    setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) {
      cancel();
    }

    axios
      .post(`/360-evaluator-lookup/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        })
      })
      .then(({ data }) => {
        setLoading(false);
        setOptions(
          data.data.map((d) => ({
            key: d.id,
            disabled: activeIds.includes(d.id),
            description: activeIds.includes(d.id)
              ? 'Already added'
              : d.manager
              ? 'Manager'
              : 'Employee',
            text: d.full_name,
            value: d.id,
            manager: d.manager
          }))
        );
      })
      .catch((err) => {
        setLoading(false);
        setOptions([]);
      });
  }

  return (
    <React.Fragment>
      {showTitle && (
        <p style={{ fontWeight: '600', marginBottom: '5px' }}>
          Add an evaluator
        </p>
      )}
      <div className="emp-type-picker">
        <Form.Radio
          label="Inside the company"
          checked={isEmployee}
          onChange={() => setIsEmployee(true)}
        />
        <Form.Radio
          label="Outside the company"
          checked={!isEmployee}
          onChange={() => setIsEmployee(false)}
        />
      </div>
      {isEmployee && (
        <Form.Select
          // label="Evaluator"
          placeholder="Start typing..."
          onSearchChange={(e, d) => fetchData(d.searchQuery)}
          onChange={(e, d) => onInputChange(d.value)}
          value={null}
          loading={loading}
          search
          options={options}
        />
      )}
      {!isEmployee && (
        <React.Fragment>
          <BasicForm
            onSubmit={(e) => onOtherAdd(e)}
            buttonText="Add Evaluator"
            fields={[
              {
                grouped: true,
                fields: [
                  {
                    name: 'first_name',
                    label: 'First Name',
                    initialValue: '',
                    required: true,
                    schema: () => Yup.string().required('Required')
                  },
                  {
                    name: 'last_name',
                    label: 'Last Name',
                    initialValue: '',
                    required: true,
                    schema: () => Yup.string().required('Required')
                  }
                ]
              },
              {
                name: 'email',
                label: 'Email',
                initialValue: '',
                required: true,
                schema: () =>
                  Yup.string()
                    .email('Not a valid email...')
                    .required('Required')
              },
              {
                name: 'relationship',
                label: `Relationship to ${targetName}`,
                initialValue: null,
                required: true,
                select: true,
                options: relationshipOptions,
                schema: () => Yup.number().nullable().required('Required')
              },
              {
                name: 'other_rater_context',
                label: `If the relationship is other, please specify`,
                initialValue: '',
                required: false,
                schema: () => null
              }
            ]}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

PersonSearch.defaultProps = {
  showTitle: true
};
