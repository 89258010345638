import React, { Component } from 'react';
import axios from 'axios';
import Slide from 'react-reveal/Slide';
import Loader from 'react-loader-spinner';
import { v4 as uuidv4 } from 'uuid';

import './index.scss';

import CandidateForm from './CandidateForm';
import Footer from './Footer';
import Header from './Header';
import QuestionItem from './QuestionItem';
import PrintReport from './PrintReport';
import ProceedModal from './ProceedModal';
import Progress from './Progress';
import SampleQuestion from './SampleQuestion';
import SubmitSuccess from './SubmitSuccess';
import SubmitText from './SubmitText';
import Terms from './Terms';
import UnansweredQuestions from './UnansweredQuestions';

import surveyVarietyFormatter from './helpers/surveyVarietyFormatter';

import bgPattern from 'assets/images/survey-bg.png';
import { ActionButton } from 'components/shared/Buttons';

const surveyStyles = {
  backgroundImage: `url(${bgPattern})`
};

const loaderConfig = {
  type: 'Watch',
  height: 50,
  width: 50,
  color: '#0A6C75'
};

const Layout = (props) => (
  <div
    className={`candidate-survey${
      props.surveyVariety === 3 ? ' hol-survey' : ''
    }`}
    style={surveyStyles}
  >
    <Header
      surveyVariety={props.surveyVariety}
      logoFilename={props.logoFilename}
    />
    {props.children}
    <Footer />
  </div>
);

function populateSelectedImportance(question) {
  if (question.variety !== 4) return null;

  let selected = null;

  ['a', 'b', 'c', 'd', 'e'].forEach((k) => {
    if (question[`response_${k}_importance`]) {
      selected = k;
    }
  });

  return selected;
}

function populateSelectedAnswer(question) {
  if (question.variety === 3 && question.multiple_choice) {
    let selected = [];
    ['a', 'b', 'c', 'd', 'e'].forEach((k) => {
      if (question[`response_${k}`]) {
        selected.push(k);
      }
    });

    return selected;
  } else if (question.variety === 5 && question.multiple_choice) {
    let selected = [];
    ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l'].forEach(
      (k) => {
        if (question[`response_${k}`]) {
          selected.push(k);
        }
      }
    );

    return selected;
  } else if (question.variety === 4) {
    let selected = null;
    ['a', 'b', 'c', 'd', 'e'].forEach((k) => {
      if (question[`response_${k}`]) {
        selected = k;
      }
    });
    return selected;
  } else {
    let selected = null;
    Object.keys(question).forEach((k) => {
      if (k.match(/(response_[^)]*$)/)) {
        selected = k.replace('response_', '');
      }
    });
    if (question.open_response_reply) {
      selected = question.open_response_reply;
    }
    if (question.response_true_false !== undefined) {
      selected = question.response_true_false ? 'true' : 'false';
    }
    return selected;
  }
}

class CandidateSurvey extends Component {
  state = {
    activeQuestionStart: null,
    autocompleteLoading: false,
    closed: false,
    // init stuff
    initError: false,
    initLoading: true,
    // survey stuff
    surveyName: '',
    instructions: '',
    questions: [],
    activeQuestion: 1,
    targetName: '',
    // employee stuff
    firstName: '',
    lastName: '',
    middleName: '',
    // company stuff
    companyLogoFilename: '',
    // time stuff
    startedAt: new Date(),
    endedAt: new Date(),
    // submit stuff
    submittedAt: null,
    submitError: null,
    submitSuccess: false,
    submitting: false,

    // proceed modal stuff
    proceedModalOpen: false,

    termsAccepted: false,
    variety: 0,

    // evaluating manager stuff..
    employee: null
  };

  componentDidMount = () => {
    // this.setupBeforeUnloadListener();
    this.fetchSurvey();
  };

  sendSurveyElapsed = () => {
    const req = {
      started_at: this.state.startedAt.toISOString(),
      ended_at: new Date().toISOString()
    };
  };

  setupBeforeUnloadListener = () => {
    window.addEventListener('beforeunload', (ev) => {
      return this.sendSurveyElapsed();
    });
  };

  fetchSurvey = () => {
    axios
      .get(`/employee-surveys/${this.props.surveyId}/`)
      .then(({ data }) => {
        let person = null;

        if (data.employee) {
          person = { ...data.employee };
        }

        if (data.manager) {
          person = { ...data.manager };
        }

        if (data.closed) {
          return this.setState({ initLoading: false, closed: true });
        }

        // no person, this is bad
        if (!person) {
          return this.setState({ initLoading: false, initError: 'Error CM' });
        }

        // non actionable survey....
        if (data.activity_status !== 0 && data.activity_status !== 1) {
          return this.setState({
            initLoading: false,
            firstName: person.first_name,
            lastName: person.last_name,
            submittedAt: data.submitted_at,
            submitSuccess: true,
            variety: data.variety
          });
        }

        const surveyQuestions = surveyVarietyFormatter(
          data.variety,
          data.questions,
          person ? person.first_name : 'Candidate'
        );
        // no questions
        if (surveyQuestions.length === 0) {
          return this.setState({ initLoading: false, initError: 'Error QM.' });
        }

        const questions = surveyQuestions.map((q) => ({
          ...q,
          selected: populateSelectedAnswer(q),
          selected_importance: populateSelectedImportance(q)
        }));

        // * find the first unanswered question, this is a sample for clear screens
        let activeQuestion = questions.find((q) => !q.selected);

        // * it's a sample question, but questions from this test have been answered
        if (
          questions.find(
            (q) => q.selected && JSON.stringify(q.selected) !== '[]'
          )
        ) {
          // * make active question the first unanswered question
          const lastQuestion = questions[questions.length - 1];
          const answeredQuestions = questions.filter(
            (q) => q.selected && JSON.stringify(q.selected) !== '[]'
          );
          const lastUnansweredQuestion =
            answeredQuestions[answeredQuestions.length - 1];
          activeQuestion =
            lastUnansweredQuestion.position === lastQuestion.position
              ? lastQuestion.position
              : // : lastUnansweredQuestion.position + 2;
                lastUnansweredQuestion.position + 1;
        } else {
          if (activeQuestion) {
            activeQuestion = activeQuestion.position;
          }
        }

        if (activeQuestion === undefined) {
          if (
            !questions.find(
              (f) => JSON.stringify(f.selected) === '[]' || f.selected === null
            )
          ) {
            activeQuestion = questions[questions.length - 1].position;
          } else {
            activeQuestion = questions[0].position;
          }
        }

        const toEvaluate =
          data.manager &&
          data.performance_info &&
          data.performance_info.find((f) => f.is_employee);

        this.setState({
          activeQuestion,
          companyLogoFilename: data.company_logo,
          firstName: person.first_name,
          initLoading: false,
          instructions: data.instruction_text,
          lastName: person.last_name,
          surveyName: data.name,
          questions,
          variety: data.variety,
          targetName: data.target_name,
          employee: toEvaluate
            ? `${toEvaluate.first_name} ${toEvaluate.last_name}`
            : null
        });
      })
      .catch((err) => {
        this.setState({
          initLoading: false,
          initError:
            err.response.data.ui_message ||
            `Something went wrong. We're looking into it.`
        });
      });
  };

  populatePreviousQuestion = () => {
    let { activeQuestion } = this.state;
    let questions = [...this.state.questions];
    let question = questions.find((q) => q.position === activeQuestion - 1);

    if (!question) {
      return null;
    }

    return (
      <QuestionItem
        key={uuidv4()}
        onClick={() => this.onBackNextClick(question.id)}
        showAnswers={false}
        inactive
        previous
        text={question.text}
      />
    );

    // return (
    //   <Slide key={uuidv4()} top unmountOnExit={true}>
    //     <QuestionItem
    //       onClick={() => this.onBackNextClick(question.id)}
    //       showAnswers={false}
    //       inactive
    //       previous
    //       text={question.text}
    //     />
    //   </Slide>
    // );
  };

  populateNextQuestion = () => {
    let { activeQuestion } = this.state;
    let questions = [...this.state.questions];
    let question = questions.find((q) => q.position === activeQuestion + 1);

    if (activeQuestion.sample) {
      return null;
      // let indexOfActiveQuestion = questions.findIndex(q => q.text === activeQuestion.text);
      // let question = questions[indexOfActiveQuestion + 1];

      // return (
      //   <SampleQuestion
      //     next
      //     inactive
      //     text={question.text}
      //     onClick={() => this.setActiveQuestion(null, indexOfActiveQuestion + 1)}
      //     showAnswers={false}
      //   />
      // )
    }

    if (!question) {
      question = questions[activeQuestion + 1];

      if (!question) return null;
    }

    return (
      <QuestionItem
        key={uuidv4()}
        onClick={() => this.onBackNextClick(question.id)}
        key={uuidv4()}
        showAnswers={false}
        inactive
        next
        text={question.text}
      />
    );

    // return (
    //   <Slide key={uuidv4()} top unmountOnExit={true}>
    //     <QuestionItem
    //       onClick={() => this.onBackNextClick(question.id)}
    //       showAnswers={false}
    //       inactive
    //       next
    //       text={question.text}
    //     />
    //   </Slide>
    // );
  };

  populateActiveQuestion = () => {
    let { activeQuestion } = this.state;
    let questions = [...this.state.questions];

    let question = questions.find((q) => q.position === activeQuestion);

    if (!question) {
      return null;
    }

    if (question.sample) {
      return (
        <SampleQuestion
          answerA={question.a}
          answerB={question.b}
          answerC={question.c}
          answerD={question.d}
          answerE={question.e}
          header={question.header}
          instructionText={question.instruction_text}
          text={question.text}
          selected={question.selected}
          onAnswerClick={(answer) => this.setSampleAnswer(question.id, answer)}
        />
      );
    }

    return (
      <QuestionItem
        key={uuidv4()}
        on360Answer={(selectedResponse, importance = false) =>
          this.set360Answer(question.id, selectedResponse, importance)
        }
        onAnswerClick={(answer) => this.setAnswer(question.id, answer)}
        onNoneOfTheAboveClick={(answerKey) =>
          this.onNoneOfTheAboveClick(question.id, answerKey)
        }
        onAnswerResponse={(answer) =>
          this.setAnswerResponse(question.id, answer)
        }
        onCommentResponse={(comment) =>
          this.setQuestionComment(question.id, comment)
        }
        text={question.text}
        descriptor={question.descriptor}
        multipleChoice={question.multiple_choice || false}
        trueFalse={question.true_false || false}
        yesNo={question.yes_no || false}
        categoryString={question.category_string}
        openResponse={question.open_response}
        openResponseReply={question.open_response_reply}
        hasComment={question.has_respondant_comment}
        comment={question.respondant_comment}
        answerA={question.a}
        answerB={question.b}
        answerC={question.c}
        answerD={question.d}
        answerE={question.e}
        answerF={question.f}
        answerG={question.g}
        answerH={question.h}
        answerI={question.i}
        answerJ={question.j}
        answerK={question.k}
        answerL={question.l}
        hol={question.variety === 3}
        is360={question.variety === 4}
        isCompetency={question.variety === 5}
        selected={question.selected}
        selectedImportance={question.selected_importance}
      />
    );

    // return (
    //   <Slide key={uuidv4()} top unmountOnExit={true}>
    //     <QuestionItem
    //       onAnswerClick={(answer) => this.setAnswer(question.id, answer)}
    //       onAnswerResponse={(answer) =>
    //         this.setAnswerResponse(question.id, answer)
    //       }
    //       onCommentResponse={(comment) =>
    //         this.setQuestionComment(question.id, comment)
    //       }
    //       text={question.text}
    //       descriptor={question.descriptor}
    //       multipleChoice={question.multiple_choice || false}
    //       trueFalse={question.true_false || false}
    //       yesNo={question.yes_no || false}
    //       categoryString={question.category_string}
    //       openResponse={question.open_response}
    //       openResponseReply={question.open_response_reply}
    //       hasComment={question.has_respondant_comment}
    //       comment={question.respondant_comment}
    //       answerA={question.a}
    //       answerB={question.b}
    //       answerC={question.c}
    //       answerD={question.d}
    //       answerE={question.e}
    //       selected={question.selected}
    //     />
    //   </Slide>
    // );
  };

  onBackNextClick = (questionId = null, nextQuestionIndex = null) => {
    let question = this.state.questions.find((q) => q.id === questionId);
    if (!question) {
      question = this.state.questions[nextQuestionIndex];
    }

    if (!question) return;

    const previousQuestion = this.state.questions.find(
      (q) => q.position === this.state.activeQuestion
    );

    this.sendElapsedQuestionTime(previousQuestion);

    this.setState({
      activeQuestion: question.position || question,
      activeQuestionStart: new Date()
    });
  };

  setActiveQuestion = (questionId = null, nextQuestionIndex = null) => {
    let question = this.state.questions.find((q) => q.id === questionId);
    if (!question) {
      question = this.state.questions[nextQuestionIndex];
    }

    if (!question) return;

    this.setState({
      activeQuestion: question.position || question,
      activeQuestionStart: new Date()
    });
  };

  sendElapsedQuestionTime = (question, answered = false, answerString) => {
    const { activeQuestionStart } = this.state;
    if (true) return;
    if (!question) return;

    // establish a base elapsed time (in seconds) for question
    // presentations we wish to record
    const elapsedThreshold = 1;

    const endTime = new Date();
    const elapsed = Math.abs(
      (activeQuestionStart.getTime() - endTime.getTime()) / 1000
    );

    if (elapsedThreshold > elapsed) return;

    const req = {
      answered,
      end: endTime.toString(),
      question_id: question.id,
      response_value: answerString || null,
      start: activeQuestionStart.toString()
    };

    axios.post(`/question-presentation/`, req);
  };

  set360Answer = (questionId, response, importance) => {
    let questions = [...this.state.questions];
    let question = questions.find((q) => q.id === questionId);
    if (!question) return;

    if (importance) {
      question.selected_importance = response;
    } else {
      question.selected = response;
    }

    this.setState({ questions });

    if (!question.selected_importance || !question.selected) return;

    const req = {
      question_id: questionId,
      [`response_${question.selected}`]: true,
      [`response_${question.selected_importance}_importance`]: true
    };

    let nextQuestion = questions.find(
      (q) => q.position === question.position + 1
    );

    axios.post(`/questions/${question.id}/record-response/`, req).then(() => {
      this.setState({
        activeQuestion: nextQuestion
          ? nextQuestion.position
          : this.state.activeQuestion
      });
    });
  };

  setAnswer = (questionId, selected) => {
    let questions = [...this.state.questions];
    let question = questions.find((q) => q.id === questionId);
    if (!question) return;

    if ([3].includes(question.variety)) {
      if (question.selected.includes(selected)) {
        question.selected = question.selected.filter((f) => f !== selected);
      } else {
        question.selected = [...question.selected, selected];
      }
    } else if ([5].includes(question.variety)) {
      // remove answer from selected
      if (question.selected.includes(selected)) {
        question.selected = question.selected.filter((f) => f !== selected);
      } else {
        let noneOfTheAboveAnswerKey = null;
        const getNoneOfTheAboveQuestionKey = Object.keys(question).forEach(
          (f) => {
            if (question[f] == 'None of the above') {
              noneOfTheAboveAnswerKey = f;
            }

            return null;
          }
        );
        question.selected = [...question.selected, selected].filter(
          (f) => f !== noneOfTheAboveAnswerKey
        );
      }
    } else {
      question.selected = selected;
    }

    let nextQuestion = questions.find(
      (q) => q.position === question.position + 1
    );

    const postData = {
      question_id: question.id
    };

    if (question.variety === 3) {
      // handle trues
      question.selected.forEach((k) => {
        postData[`response_${k}`] = true;
      });
      // handle falses
      ['a', 'b', 'c', 'd', 'e'].forEach((k) => {
        if (!question.selected.includes(k)) {
          postData[`response_${k}`] = false;
        }
      });
    } else if (question.variety === 5) {
      // handle trues
      question.selected.forEach((k) => {
        postData[`response_${k}`] = true;
      });
      // handle falses
      ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l'].forEach(
        (k) => {
          if (!question.selected.includes(k)) {
            postData[`response_${k}`] = false;
          }
        }
      );
    } else {
      postData[`response_${selected}`] = true;
    }

    if (question.true_false) {
      postData.response_true_false = selected === 'true' ? true : false;
    }

    this.sendElapsedQuestionTime(question, true, question[selected]);

    axios
      .post(`/questions/${question.id}/record-response/`, postData)
      .then(() => {
        if (question.has_respondant_comment)
          return this.setState({ questions });

        this.setState({
          questions,
          activeQuestion: [3, 5].includes(question.variety)
            ? this.state.activeQuestion
            : nextQuestion
            ? nextQuestion.position
            : this.state.activeQuestion
        });
      })
      .catch((err) => {});
  };

  onNoneOfTheAboveClick = (questionId, selected) => {
    let questions = [...this.state.questions];
    let question = questions.find((q) => q.id === questionId);
    if (!question) return;

    if (question.selected.includes(selected)) {
      return;
    } else {
      question.selected = [selected];
    }

    let nextQuestion = questions.find(
      (q) => q.position === question.position + 1
    );

    const req = {
      question_id: questionId
    };

    question.selected.forEach((k) => {
      req[`response_${k}`] = true;
    });
    // handle falses
    ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l'].forEach(
      (k) => {
        if (!question.selected.includes(k)) {
          req[`response_${k}`] = false;
        }
      }
    );

    axios
      .post(`/questions/${question.id}/record-response/`, req)
      .then(() => {
        this.setState({
          questions,
          activeQuestion: nextQuestion
            ? nextQuestion.position
            : this.state.activeQuestion
        });
      })
      .catch((err) => {});
  };

  setQuestionComment = (questionId, comment) => {
    let questions = [...this.state.questions];
    let question = questions.find((q) => q.id === questionId);
    if (!question) return;

    question.respondant_comment = comment;

    let nextQuestion = questions.find(
      (q) => q.position === question.position + 1
    );

    const postData = {
      question_id: question.id,
      respondant_comment: comment
    };

    axios
      .post(`/questions/${question.id}/record-comment/`, postData)
      .then(() => {
        // this.setState({
        //   questions,
        //   activeQuestion: nextQuestion
        //     ? nextQuestion.position
        //     : this.state.activeQuestion
        // });
      })
      .catch((err) => {});
  };

  setAnswerResponse = (questionId, answer) => {
    let questions = [...this.state.questions];
    let question = questions.find((q) => q.id === questionId);
    if (!question) return;

    question.open_response_reply = answer;
    question.selected = answer;

    let nextQuestion = questions.find(
      (q) => q.position === question.position + 1
    );

    const postData = {
      question_id: question.id,
      open_response_reply: answer
    };

    this.sendElapsedQuestionTime(question, true, answer);

    axios
      .post(`/questions/${question.id}/record-response/`, postData)
      .then(() => {
        this.setState({
          questions,
          activeQuestion: nextQuestion
            ? nextQuestion.position
            : this.state.activeQuestion
        });
      })
      .catch((err) => {});
  };

  setSampleAnswer = (questionId, answer) => {
    const questions = [...this.state.questions];
    const question = questions.find((q) => q.id === questionId);

    question.selected = answer;

    if (question.last_sample) {
      this.setState({
        questions,
        proceedModalOpen: true
      });
    } else {
      this.setState({
        questions,
        activeQuestion: question.position + 1
      });
    }
  };

  onCandidateSubmit = (formData) => {
    this.setState({
      firstName: formData.first_name,
      middleName: formData.middle_name,
      lastName: formData.last_name
    });
  };

  onQuestionClick = (questionId) => {
    let question = this.state.questions.find((q) => q.id === questionId);
    if (!question) return;

    this.setState({ activeQuestion: question.position });
  };

  onSurveySubmit = () => {
    // this.setState({ submitSuccess: true });
    this.setState({ submitting: true });

    axios
      .post(`/employee-surveys/${this.props.surveyId}/submit/`)
      .then(({ data }) => {
        this.setState({
          submitting: false,
          submittedAt: new Date(),
          submitSuccess: true,
          scores: data
        });
      })
      .catch((err) => {
        this.setState({
          submitError: true,
          submitting: false
        });
      });
  };

  onTermsAccept = () => {
    this.setState({ termsAccepted: true, activeQuestionStart: new Date() });
    window.scrollTo(0, 0);
  };

  onSubmitErrorClear = () => {
    this.setState(
      {
        submitError: null
      },
      () => this.fetchSurvey()
    );
  };

  onAutocompleteClick = () => {
    this.setState({ autocompleteLoading: true });
    axios
      .post(`/surveys/${this.props.surveyId}/autoresponder/`)
      .then(() => this.setState({ autocompleteLoading: false }))
      .then(() => this.fetchSurvey());
  };

  onProceedNoClick = () => this.setState({ proceedModalOpen: false });

  onProceedYesClick = () => {
    this.setState({
      activeQuestion: this.state.activeQuestion + 1,
      proceedModalOpen: false
    });
  };

  getActiveQuestion = () => {
    return this.state.questions.find(
      (q) => q.position === this.state.activeQuestion
    );
  };

  render() {
    const {
      closed,
      questions,
      submitting,
      submitError,
      submitSuccess,
      termsAccepted,
      variety
    } = this.state;

    const is360 = variety === 4;

    const targetName = this.state.targetName;

    const activeQuestion = this.getActiveQuestion();

    const employeeName = `${this.state.firstName} ${this.state.lastName}`;

    const unansweredQuestions = is360
      ? questions.filter((f) => !f.selected || !f.selected_importance)
      : questions.filter(
          (f) =>
            (JSON.stringify(f.selected) === '[]' || !f.selected) && !f.sample
        );

    const questionsComplete = is360
      ? questions.filter((f) => f.selected && f.selected_importance).length
      : questions.filter(
          (f) => f.selected && !f.sample && JSON.stringify(f.selected) !== '[]'
        ).length;
    const progressPercentage =
      (questionsComplete / questions.filter((f) => !f.sample).length) * 100;
    const candidateComplete =
      this.state.firstName && this.state.lastName ? true : false;

    const showAutocomplete =
      process.env.ENV == 'qa' || process.env.ENV == 'dev';

    if (this.state.initLoading) return <div></div>;

    if (this.state.closed) {
      return (
        <Layout
          surveyVariety={variety}
          logoFilename={this.state.companyLogoFilename}
        >
          <div className="candidate-submit submit-errors">
            <h1>This survey is closed.</h1>
          </div>
        </Layout>
      );
    }

    if (this.state.initError) {
      return (
        <Layout
          surveyVariety={variety}
          logoFilename={this.state.companyLogoFilename}
        >
          <div className="candidate-submit submit-errors">
            <h1>Yikes!</h1>
            <p>{this.state.initError}</p>
          </div>
        </Layout>
      );
    }

    if (submitSuccess) {
      return (
        <Layout
          surveyVariety={variety}
          logoFilename={this.state.companyLogoFilename}
        >
          <SubmitSuccess
            name={this.state.firstName}
            submittedAt={new Date(this.state.submittedAt)}
            variety={this.state.variety}
            // REMOVE ME
            scores={this.state.scores}
          />
          {/* if performance review, allow questions to be printed */}
          {variety === 1 && (
            <PrintReport
              questions={questions}
              surveyName={this.state.surveyName}
              employeeName={employeeName}
            />
          )}
        </Layout>
      );
    }

    if (!termsAccepted) {
      return (
        <Layout
          surveyVariety={variety}
          logoFilename={this.state.companyLogoFilename}
        >
          <div className="test-wrapper">
            <Terms
              instructions={this.state.instructions}
              name={employeeName}
              employee={this.state.employee}
              variety={this.state.variety}
            >
              <ActionButton
                text={
                  variety === 0
                    ? 'Accept'
                    : variety === 5
                    ? `Let's Go!`
                    : 'Continue'
                }
                onClick={this.onTermsAccept}
                style={{ width: '100%', marginTop: '20px' }}
              />
            </Terms>
          </div>
        </Layout>
      );
    }

    if (!candidateComplete) {
      return (
        <Layout
          surveyVariety={variety}
          logoFilename={this.state.companyLogoFilename}
        >
          <div className="test-wrapper">
            <CandidateForm
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              middleName={this.state.middleName}
              onSubmit={this.onCandidateSubmit}
            />
          </div>
        </Layout>
      );
    }

    if (submitting) {
      return (
        <Layout
          surveyVariety={variety}
          logoFilename={this.state.companyLogoFilename}
        >
          <div className="candidate-submit">
            <Loader {...loaderConfig} />
            <h2>Submitting...</h2>
          </div>
        </Layout>
      );
    }

    if (submitError) {
      return (
        <Layout
          surveyVariety={variety}
          logoFilename={this.state.companyLogoFilename}
        >
          <div className="candidate-submit submit-errors">
            <h1>Yikes!</h1>
            <p>{submitError}</p>
            <ActionButton
              onClick={this.onSubmitErrorClear}
              text="Try Again"
              style={{ fontWeight: 'bold' }}
            />
          </div>
        </Layout>
      );
    }

    return (
      <Layout
        surveyVariety={variety}
        logoFilename={this.state.companyLogoFilename}
      >
        <ProceedModal
          // header={this.state.activeQuestion.header}
          // header={questions[this.getActiveQuestionIndex()].header}
          open={this.state.proceedModalOpen}
          onNoClick={this.onProceedNoClick}
          onYesClick={this.onProceedYesClick}
        />
        {progressPercentage > 0 && (
          <UnansweredQuestions
            questions={unansweredQuestions}
            onQuestionClick={this.onQuestionClick}
          />
        )}
        <div className="test-wrapper">
          <div className="questions">
            {this.populatePreviousQuestion()}
            {targetName && <h1 className="target-360-name">{targetName}</h1>}
            {this.populateActiveQuestion()}
            {this.populateNextQuestion()}
          </div>
          {showAutocomplete && (
            <React.Fragment>
              {unansweredQuestions.length !== 0 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '30px'
                  }}
                >
                  <ActionButton
                    text="Autocomplete"
                    loading={this.state.autocompleteLoading}
                    onClick={this.onAutocompleteClick}
                  />
                </div>
              )}
            </React.Fragment>
          )}
          {unansweredQuestions.length === 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px'
              }}
            >
              <SubmitText
                surveyId={this.props.surveyId}
                onSubmitConfirm={this.onSurveySubmit}
                variety={this.state.variety}
              />
            </div>
          )}
          {activeQuestion &&
            !activeQuestion.sample &&
            unansweredQuestions.length > 0 && (
              <Progress percentage={progressPercentage} />
            )}
        </div>
      </Layout>
    );
  }
}

CandidateSurvey.defaultProps = {
  surveyId: null
};

export default CandidateSurvey;
