import React, { useState, useEffect } from 'react';
import { GrInfo } from 'react-icons/gr';
import { VictoryBar, VictoryGroup, VictoryAxis, VictoryChart } from 'victory';

import { FilterButtons } from 'components/shared/Buttons';

import { colors } from '../colors';

const barBaseColors = [
  colors.self,
  colors.superior,
  colors.colleague,
  colors.subordinate,
  colors.all
];

function getBarColor(x, y) {
  const baseColor = barBaseColors[x - 1];

  return baseColor;
}

export default function BarChart({
  skills,
  importance,
  tickFormat,
  // width,
  height,
  hideLabels,
  barWidth
}) {
  const [activeBars, setActiveBars] = useState(null);

  const widths = {
    div: skills.length * (!hideLabels ? 166.66667 : 30),
    chart:
      skills.length > 0
        ? skills.length * 166.66667 * (hideLabels ? 0.65 : 0.85)
        : 0
  };

  return (
    <React.Fragment>
      <div style={{ width: `${widths.div}px`, height: `${height}px` }}>
        <VictoryChart
          animate={!hideLabels ? { duration: 500, easing: 'sinIn' } : null}
          // domainPadding={{ x: 50 }}
          width={widths.chart}
          height={350}
        >
          <VictoryGroup
            offset={!hideLabels ? 50 : 35}
            // style={{ data: { width: 15 } }}
          >
            {/* skill */}
            {[null, 'skill'].includes(activeBars) && (
              <VictoryBar
                barWidth={barWidth}
                cornerRadius={5}
                style={{
                  ...barStyle,
                  data: {
                    ...barStyle.data,
                    fill: ({ datum }) => {
                      return datum.color;
                      // return getBarColor(datum.x, datum.y);
                    },
                    opacity: 0.55
                  }
                }}
                data={[...skills]}
                labels={({ datum }) =>
                  !hideLabels ? formatBarLabel(datum.y) : null
                }
              />
            )}
            {/* importance */}
            {[null, 'importance'].includes(activeBars) && (
              <VictoryBar
                barWidth={barWidth}
                cornerRadius={5}
                style={{
                  ...barStyle,
                  data: {
                    ...barStyle.data,
                    fill: ({ datum }) => {
                      return datum.color;
                      // return getBarColor(datum.x, datum.y);
                    }
                  }
                }}
                data={[...importance]}
                labels={({ datum }) =>
                  !hideLabels ? formatBarLabel(datum.y) : null
                }
              />
            )}
          </VictoryGroup>
          {!hideLabels && (
            <VictoryAxis
              tickFormat={tickFormat}
              style={{
                tickLabels: {
                  fontSize: 14,
                  fontFamily: 'inherit',
                  fontWeight: 600,
                  fill: '#f1f5f8'
                },
                axis: {
                  // stroke: 'whitesmoke'
                  stroke: 'transparent'
                }
              }}
            />
          )}
          {hideLabels && (
            <VictoryAxis
              style={{
                axis: { stroke: 'transparent' },
                ticks: { stroke: 'transparent' },
                tickLabels: { fill: 'transparent' }
              }}
            />
          )}
        </VictoryChart>
      </div>
      {!hideLabels && (
        <div style={{ width: '100%' }}>
          <p className="guide">
            <GrInfo />{' '}
            {activeBars === 'skill' && (
              <React.Fragment>
                Bars represent <b>skill</b>.
              </React.Fragment>
            )}
            {activeBars === 'importance' && (
              <React.Fragment>
                Bars represent <b>importance</b>.
              </React.Fragment>
            )}
            {activeBars === null && (
              <React.Fragment>
                Bar on left represents <b>skill</b>. Bar on right represents{' '}
                <b>importance</b>
              </React.Fragment>
            )}
          </p>
          <FilterButtons
            buttons={[
              { text: 'Skill & Importance ', value: null },
              { text: 'Skill ', value: 'skill' },
              { text: 'Importance ', value: 'importance' }
            ].map((m) => ({
              ...m,
              active: activeBars === m.value
            }))}
            active={activeBars}
            onClick={(e) => setActiveBars(e)}
          />
        </div>
      )}
    </React.Fragment>
  );
}

BarChart.defaultProps = {
  barWidth: 45,
  height: 350,
  width: 500,
  skills: [],
  importance: [],
  tickFormat: [
    'Self',
    'Superior(s)',
    'Colleagues',
    'Subordinates',
    'All Raters (no self)'
  ],
  hideLabels: false
};

const barStyle = {
  data: {
    // stroke: ({ index }) => (+index % 2 === 0 ? '#000000' : '#c43a31'),
    fillOpacity: 0.7
    // strokeWidth: 3
  },
  labels: {
    fontSize: 11,
    fontFamily: 'inherit',
    fontWeight: 600,
    fill: ({ datum }) => '#f1f5f8'
  }
};

function formatBarLabel(x) {
  return (Math.round(x * 10) / 10).toFixed(1);
}

function generateRandomNumber(num = 5) {
  // return Math.floor(Math.random() * (5 - 0 + 1) + 0);
  const precision = 10;
  return (
    Math.floor(
      Math.random() * (num * precision - 1 * precision) + 1 * precision
    ) /
    (1 * precision)
  );
}
