import React, { Component } from 'react';
import ReactTable from 'react-table';
import axios from 'axios';

import { FaCircle } from 'react-icons/fa';

import { IconFilterButtons } from 'components/shared/Buttons';
import { TableLinkButton } from 'components/shared/Buttons';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    sortable: false,
    filterable: true
  },
  {
    Header: 'Variety',
    accessor: 'variety_formatted',
    width: 200,
    sortable: false
  },
  {
    Header: 'Created',
    id: 'created',
    width: 200,
    sortable: false,
    accessor: (d) =>
      d.created_at ? new Date(d.created_at).toLocaleString() : ''
  },
  {
    Header: 'Versions',
    accessor: 'version_count',
    sortable: false,
    width: 100
  },
  {
    Header: '',
    id: 'view',
    sortable: false,
    width: 40,
    accessor: (d) => <TableLinkButton url={`/surveys/${d.id}`} />
  }
];

const statusFilterButtons = [
  { text: 'Active', value: 0, icon: <FaCircle /> },
  { text: 'Inactive', value: 1, icon: <FaCircle /> }
];

const varietyFilterButtons = [{ text: 'Clear Screen', value: 0 }];

class Table extends Component {
  state = {
    loading: true,
    pages: null,
    status: 0,
    tableData: [],
    total: 0,
    variety: 0
  };

  table = React.createRef();

  onStatusButtonClick = (status) => {
    if (this.state.status !== status) {
      this.setState({ status });
      this.onFetchData(this.table.current.state, null, status);
    }
  };

  onVarietyButtonClick = (filterValue) => {};

  onFetchData = (
    state,
    instance,
    status = this.state.status,
    variety = this.state.variety
  ) => {
    const { page, pageSize, sorted, filtered } = state;

    filtered.forEach((filter) => {
      filter.object = 'self';
      // const filterOption = this.props.filters.find(f => f.name === filter.id);

      // filter.object = filterOption.obj;
      // filter.object = self;
    });

    this.setState({ loading: true });

    let postData = {
      page: page * pageSize,
      page_size: pageSize,
      sorted,
      filtered,
      status,
      variety
    };

    axios
      .post(`/retrieve-surveys/`, postData)
      .then(({ data }) => {
        let pages = 0;
        if (data.total < pageSize) {
          pages = 1;
        } else {
          pages = Math.ceil(data.total / pageSize);
        }

        this.setState({
          loading: false,
          pages,
          tableData: data.data,
          total: data.total ? data.total : 0
        });
      })
      .catch((err) => {
        this.setState({
          loading: false
        });
      });
  };

  render() {
    return (
      <div className="segment">
        <div className="filter-button-header">
          <IconFilterButtons
            active={this.state.status}
            buttons={statusFilterButtons}
            loading={this.state.loading}
            onClick={this.onStatusButtonClick}
            text="Status"
          />
          {/*
              <IconFilterButtons
                active={this.state.variety}
                buttons={varietyFilterButtons}
                loading={this.state.loading}
                onClick={this.onVarietyButtonClick}
                text="Variety"
              />
            */}
        </div>
        <ReactTable
          className="-ui -striped -table"
          columns={columns}
          data={this.state.tableData}
          defaultPageSize={20}
          // defaultSorted="name"
          loading={this.state.loading}
          loadingText={`Loading Surveys`}
          manual
          minRows={5}
          multiSort={false}
          noDataText={`No Surveys Available`}
          onFetchData={this.onFetchData}
          pages={this.state.pages}
          ref={this.table}
          rowsText="Surveys"
        />
      </div>
    );
  }
}

export default Table;
