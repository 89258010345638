import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import {
  AuthWrapper,
  Code,
  ForgotPassword,
  HeadlessSession,
  Login,
  NotFound
} from 'components/Auth';

import Hol from 'components/Hol';
import CompetencyAssessment from 'components/CompetencyAssessment';

import logo from '../assets/images/logo.png';

class Auth extends Component {
  render() {
    if (this.props.error) {
      return (
        <AuthWrapper>
          <h1>Status Error!</h1>
          <p>There was a problem. Try again later.</p>
        </AuthWrapper>
      );
    }

    return (
      <Router basename="/">
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <AuthWrapper>
                <Login
                  entity="CompanyManager"
                  representer="CompanyManagerRepresenter"
                  title="Login"
                  {...props}
                />
              </AuthWrapper>
            )}
          />
          <Route
            exact
            path="/admin"
            render={(props) => (
              <AuthWrapper>
                <Login
                  entity="Admin"
                  representer="AdminRepresenter"
                  title="Admin Login"
                  {...props}
                />
              </AuthWrapper>
            )}
          />
          <Route path="/code/:id" component={Code} />
          <Route
            path="/forgot-password"
            render={(props) => (
              <AuthWrapper>
                <ForgotPassword {...props} />
              </AuthWrapper>
            )}
          />
          <Route path="/headless-session/">
            <HeadlessSession />
          </Route>
          <Route path="/heart-of-leadership">
            <Hol />
          </Route>
          <Route path="/lpsu">
            <CompetencyAssessment />
          </Route>
          <Route path="*">
            <AuthWrapper>
              <NotFound />
            </AuthWrapper>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default Auth;

Auth.defaultProps = {
  error: false
};
