import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaCircle } from 'react-icons/fa';

import { IconFilterButtons, TableLinkButton } from 'components/shared/Buttons';
import { PaginatedTable } from 'components/shared/Tables';
import { CompanySelectList } from 'components/CompanyManager';
import { EmployeeCsv } from './';

import { EmployeeCreateModal, EmployeeManagerLinks } from './';

const filterOptions = [
  { id: 'first_name', object: 'self' },
  { id: 'last_name', object: 'self' },
  { id: 'email', object: 'self' },
  { id: 'position', object: 'self' }
];

const ALL_VALUE = '[0, 1, 2, 3, 4]';

const statusFilterButtons = [
  { text: 'All', value: ALL_VALUE, icon: <FaCircle /> },
  { text: 'Active', value: 1, icon: <FaCircle /> },
  { text: 'Pending', value: 0, icon: <FaCircle /> },
  { text: 'Not Hired', value: 2, icon: <FaCircle /> },
  { text: 'Inactive', value: 3, icon: <FaCircle /> },
  { text: 'Benched', value: 4, icon: <FaCircle /> }
  // { text: 'Complete', value: 5, icon: <FaCircle /> }
];

export default function Table({
  allCompanies,
  companyId,
  companyName,
  showCompany,
  isCompanyManager
}) {
  const [companies, setCompanies] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [status, setStatus] = useState(ALL_VALUE);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (allCompanies) return fetchAllCompanies();
  }, []);

  function fetchAllCompanies() {
    axios
      .post(`/retrieve-companies/`, {
        filtered: [],
        page: 0,
        page_size: 1000,
        sorted: [],
        status: 0
      })
      .then(({ data }) =>
        setCompanyOptions(
          data.data
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((d) => ({
              key: d.id,
              text: d.name,
              value: d.id
            }))
        )
      );
  }

  let tableUrl = `/retrieve-company-employees/`;

  if (isCompanyManager) {
    tableUrl = `/retrieve-manager-company-employees/`;
  }

  let companyIds = [...companies];

  if (companyId) {
    companyIds = [companyId];
  }

  const additionalPostData = {
    company_ids: companyIds,
    status: status === ALL_VALUE ? [0, 1, 2, 3, 4] : status,
    refresh
  };

  // * Show all companies for a company manager if no companies are selected
  if (isCompanyManager && !companies.length) {
    additionalPostData.all = true;
  }

  if (allCompanies && !companies.length) {
    additionalPostData.all = true;
  }

  const columns = [
    {
      Header: 'First Name',
      id: 'first_name',
      sortable: false,
      accessor: 'first_name',
      filterable: true
    },
    {
      Header: 'Last Name',
      id: 'last_name',
      sortable: false,
      accessor: 'last_name',
      filterable: true
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: 'email',
      filterable: true,
      sortable: false
    },
    {
      Header: 'Position',
      id: 'position',
      accessor: 'position',
      filterable: true,
      sortable: false
    },
    {
      Header: 'Company',
      accessor: 'company_name',
      show: showCompany,
      sortable: false
    },
    // {
    //   Header: 'Status',
    //   accessor: 'status_formatted',
    //   show: status === ALL_VALUE,
    //   width: 100,
    //   sortable: false
    // },
    {
      Header: 'Report Ready',
      id: 'report',
      accessor: (d) => (d.report_ready ? <FaCheck /> : ''),
      sortable: false
    },
    {
      Header: 'Manager(s)',
      id: 'managers',
      show: companyId ? true : false,
      sortable: false,
      accessor: (d) => (
        <EmployeeManagerLinks
          employeeId={d.id}
          companyId={companyId}
          links={d.company_manager_employee_links || []}
          onUpdate={() => setRefresh(!refresh)}
        />
      )
    },
    {
      Header: '',
      id: 'view',
      width: 40,
      sortable: false,
      accessor: (d) => <TableLinkButton url={`/employees/${d.id}`} />
    }
  ];

  return (
    <div className="segment">
      {!companyId && (
        <div style={{ marginBottom: '10px' }}>
          <CompanySelectList
            onChange={(c) => setCompanies(c)}
            value={companies}
            companyOptions={companyOptions}
          />
        </div>
      )}
      <div style={{ margin: '20px 0' }}>
        <IconFilterButtons
          active={status}
          buttons={statusFilterButtons}
          loading={false}
          onClick={(e) => setStatus(e)}
          text="Status"
        />
      </div>
      <PaginatedTable
        additionalPostData={additionalPostData}
        columns={columns}
        entityName="Employees"
        filterOptions={filterOptions}
        keyName="data"
        url={tableUrl}
      />
      {/* {!isCompanyManager && companyId && (
        <EmployeeCsv style={{ marginTop: '1em' }} companyId={companyId} />
      )} */}
      {companyId && !isCompanyManager && (
        <div style={{ marginTop: '1em' }}>
          <EmployeeCreateModal
            companyId={companyId}
            companyName={companyName}
            onSuccess={() => setRefresh(!refresh)}
          />
        </div>
      )}
    </div>
  );
}

Table.defaultProps = {
  allCompanies: false,
  companyId: null,
  companies: [],
  showCompany: true,
  showInvite: true,
  isCompanyManager: false
};
