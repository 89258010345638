import React, { useState, useEffect } from 'react';

export default function QuestionItem(props) {
  const [openResponseValue, setOpenResponseValue] = useState('');
  const [commentValue, setCommentValue] = useState('');
  const hasCopOut = props.descriptor === 1;
  const { is360, isCompetency, hol } = props;

  function onOpenResponseBlur(e) {
    setOpenResponseValue(e.target.value);
    props.onAnswerResponse(e.target.value);
  }

  function onCommentBlur(e) {
    setCommentValue(e.target.value);
    props.onCommentResponse(e.target.value);
  }

  useEffect(() => {
    setOpenResponseValue(props.openResponseReply);
    setCommentValue(props.comment);
  }, [props.comment, props.openResponseReply]);

  function onAnswerButtonClick(e) {
    if (is360) {
      return props.on360Answer(e);
    }

    if (
      isCompetency &&
      props[`answer${e.toUpperCase()}`] == 'None of the above'
    ) {
      return props.onNoneOfTheAboveClick(e);
    }

    props.onAnswerClick(e);
  }

  function on360Answer(e, importance = false) {
    props.on360Answer(e, importance);
  }

  return (
    <div
      className={`candidate-survey-question-item${
        props.inactive ? ' inactive' : ' active'
      }${isCompetency ? ' competency-question' : ''}`}
      onClick={props.onClick ? props.onClick : null}
    >
      {props.categoryString && (
        <p className="category-string">{props.categoryString}</p>
      )}
      <h2>
        {props.next ? 'Next Up: ' : ''}
        {props.previous ? 'Back to: ' : ''}
        {props.text}
      </h2>
      {props.openResponse && (
        <textarea
          type="text"
          placeholder="Type your response here"
          onChange={(e) => setOpenResponseValue(e.target.value)}
          onBlur={onOpenResponseBlur}
          value={openResponseValue}
        />
      )}
      {props.showAnswers && (
        <AnswerButtons
          showCopOut={hasCopOut}
          multipleChoice={props.multipleChoice}
          trueFalse={props.trueFalse}
          yesNo={props.yesNo}
          answerA={props.answerA}
          answerB={props.answerB}
          answerC={props.answerC}
          answerD={props.answerD}
          answerE={props.answerE}
          answerF={props.answerF}
          answerG={props.answerG}
          answerH={props.answerH}
          answerI={props.answerI}
          answerJ={props.answerJ}
          answerK={props.answerK}
          answerL={props.answerL}
          selected={props.selected}
          onClick={onAnswerButtonClick}
          hol={hol}
          isCompetency={isCompetency}
        />
      )}
      {props.hasComment && (
        <textarea
          type="text"
          placeholder="Enter any additional information here"
          onChange={(e) => setCommentValue(e.target.value)}
          onBlur={onCommentBlur}
          value={commentValue}
        />
      )}
      {props.is360 && (
        <div style={{ marginTop: '1.15em' }} className="importance-btns">
          <h2>Level of Importance</h2>
          <AnswerButtons
            multipleChoice
            answerA={props.answerA}
            answerB={props.answerB}
            answerC={props.answerC}
            answerD={props.answerD}
            answerE={props.answerE}
            selected={props.selectedImportance}
            onClick={(e) => on360Answer(e, true)}
          />
        </div>
      )}
    </div>
  );
}

QuestionItem.defaultProps = {
  // question types
  descriptor: 0,
  multipleChoice: false,
  trueFalse: false,
  yesNo: false,
  categoryString: '',
  openResponse: false,
  openResponseReply: '',
  hasComment: false,
  comment: '',
  hol: false,
  isCompetency: false,
  // answers
  answerA: '',
  answerB: '',
  answerC: '',
  answerD: '',
  answerE: '',
  answerF: '',
  answerG: '',
  answerH: '',
  answerI: '',
  answerJ: '',
  answerK: '',
  answerL: '',
  // other
  inactive: false,
  showAnswers: true,
  text: ''
};

const AnswerButtons = (props) => {
  let buttons = [];
  const { hol, isCompetency } = props;

  if (props.trueFalse) {
    buttons = [
      { value: 'true', text: 'True' },
      { value: 'false', text: 'False' }
    ];
  }

  if (props.yesNo) {
    buttons = [
      { value: 'yes', text: 'Yes' },
      { value: 'no', text: 'No' }
    ];
  }

  if (props.multipleChoice) {
    buttons = [
      { value: 'a', text: props.answerA },
      { value: 'b', text: props.answerB },
      { value: 'c', text: props.answerC },
      { value: 'd', text: props.answerD },
      { value: 'e', text: props.answerE },
      { value: 'f', text: props.answerF },
      { value: 'g', text: props.answerG },
      { value: 'h', text: props.answerH },
      { value: 'i', text: props.answerI },
      { value: 'j', text: props.answerJ },
      { value: 'k', text: props.answerK },
      { value: 'l', text: props.answerL }
    ];
  }

  // make sure each button has text with it (ex: a: 'something', b: 'else', c: 'cool', d: 'neat', e: undefined)
  buttons = buttons.filter((b) => b.text);

  if (!buttons.length) return <div></div>;

  if (props.showCopOut) {
    return (
      <div className="answer-buttons cop-out">
        <div>
          {buttons.slice(0, 3).map((b, i) => (
            <button
              key={i}
              className={props.selected === b.value ? 'selected' : ''}
              onClick={() => props.onClick(b.value)}
            >
              {b.text}
            </button>
          ))}
        </div>
        <div>
          {buttons.slice(3, 6).map((b, i) => (
            <button
              key={i}
              className={props.selected === b.value ? 'selected' : ''}
              onClick={() => props.onClick(b.value)}
            >
              {b.text}
            </button>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="answer-buttons">
      {buttons.map((b, i) => (
        <button
          key={i}
          className={
            hol || isCompetency
              ? props.selected.includes(b.value)
                ? 'selected'
                : ''
              : props.selected === b.value
              ? 'selected'
              : ''
          }
          onClick={() => props.onClick(b.value)}
        >
          {b.text}
        </button>
      ))}
    </div>
  );
};

AnswerButtons.defaultProps = {
  hol: false,
  isCompetency: false
};
